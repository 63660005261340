import React, { useState, Fragment, useEffect } from 'react';
import styles from './PendingApprovals.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import {
	ColumnPendingApproval,
	PendingApprovalData,
} from '../../types/TablesTypes/PendingApprovals';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import TransactionsMenu from '../../components/MenuOption/TransactionsMenu/TransactionsMenu';
import transactionsApi from '../../api/transactions';
import useApi from '../../hooks/useApi';
import client from '../../api/client';
import { numberWithCommas } from '../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import Filtered from '../../components/filterComponent/Filtered';
import { menuContent } from '../../utils/menuContent';
import PendingApprovalMenu from '../../components/MenuOption/pendingApproval/PendingApproval';
import FilteredUsage from '../../components/filterUsableComponent/FilteredUsage';

const PendingApprovals = () => {
	const dispatch = useDispatch();
	const [value, setValue] = useState('');
	const [tableRow, setTableRow] = useState<any[]>();
	const [pendingApprovals, setPendingApprovals] = useState<any>();

	//for filtering
	const [dropdown, setDropdown] = useState(false);
	const [fromDate, setFromDate] = useState<string>('');
	const [toDate, setToDate] = useState<string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [orderreference, setOrderReference] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const clearHandler = () => {
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	const filteredArray = [
		{
			id: 1,
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Successful' },
				{ name: 'Initiated' },
				{ name: 'Awaiting-Confirmation' },
			],
		},
		{
			id: 2,
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
		},
		{
			id: 3,
			name: 'Order Reference',
			value: orderreference,
			setValue: setOrderReference,
		},
	];

	const fetchPendingApproval = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/subsidiary/payout/pending/approval?fromdate=${fromDate}&todate=${toDate}`
			);
			setPendingApprovals(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchPendingApproval();
	}, [bearer]);

	const dataTransaction = () => {
		const tempArr: PendingApprovalData[] = [];
		pendingApprovals?.data.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					amount: transaction.amount,
					narration: transaction.narration,
					recipient: transaction.recipient,
					date: transaction.date,
					// date: moment(transaction?.date_created).format('DD MMM YYYY'),
					action: <PendingApprovalMenu />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [pendingApprovals?.data.items]);

	return (
		<Fragment>
			<TableHeader
				pageName='Pending Approval'
				dataLength={pendingApprovals?.data?.totalItems}
				data={pendingApprovals?.data?.items}
				value={value}
				setValue={setValue}
				dropdown={dropdown}
				setDropdown={setDropdown}
				filtering={false}
				otherbutton={true}
				otherbuttonTitle='Approve all'
				// otherbuttonfunction={}
				FilterComponent={
					<>
						<FilteredUsage
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				value={value}
				columns={ColumnPendingApproval ? ColumnPendingApproval : []}
				emptyPlaceHolder='You currently do not have any data'
				total={pendingApprovals?.data?.totalItems}
			/>
		</Fragment>
	);
};

export default PendingApprovals;
