// export function convertMb(x: number) {
// 	// bytes
// 	let fileSizeMB = Math.round(x / 1024 ** 2);
// 	return fileSizeMB;
// }
export function convertMb(x: number): number {
	// Convert bytes to megabytes (MB)
	let fileSizeMB = x / 1024 ** 2;
	
	// Return rounded value if >= 1MB, otherwise return exact value with decimals
	return fileSizeMB >= 1 ? Math.round(fileSizeMB) : parseFloat(fileSizeMB.toFixed(2));
  }
  