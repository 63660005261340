import React, { useState, Fragment, ReactNode } from "react";
import styles from "./Deliveryfee.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";

type itemProps = {
  name: string;
  fee: number;
};
type DeliveryProps = {
  closeModal?: () => void;
  ModalBody: ReactNode;
  onClick?: () => void;
  fee?: itemProps;
};
const DeliveryFee = ({
  closeModal,
  ModalBody,
  onClick,
  fee,
}: DeliveryProps) => {
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Delivery Fee</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>{ModalBody}</div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {!!fee?.name && !!fee?.fee ? (
          <div className={styles.deactivate} onClick={onClick}>
            Add fee
          </div>
        ) : (
          <div className={styles.notClickable}>Add fee</div>
        )}
      </div>
    </div>
  );
};

export default DeliveryFee;
