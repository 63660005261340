import axios from 'axios';
import { getEnvironmentConfig } from './../utils/getEnvironmentConfig';

const client = axios.create({
	baseURL: getEnvironmentConfig("ROOTURL"),
	headers: {
		'content-type': 'application/json',
		CORS_ALLOW_ALL_ORIGINS: true,
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Headers": " Content-Type",
		"Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
	},
});

client.interceptors.request.use(
	async (config) => {
		const access_token = sessionStorage.getItem('accessToken');
		config.headers["Authorization"] = `Bearer ${access_token}`;
		return config;
	},
	async (error) => {
		if (error?.response?.data?.message === 'Please login again') {
			window.location.href = '/';
		} else return Promise.reject(error);
	}
);

export const formClient = axios.create({
	baseURL: getEnvironmentConfig("ROOTURL"),
	headers: {
		// 'content-type': 'multipart/form-data',
		'content-type': 'application/json',
	},
});

formClient.interceptors.request.use(
	async (config) => {
		const access_token = sessionStorage.getItem('accessToken');
		config.headers.authorization = `Bearer ${access_token}`;
		return config;
	},
	(error) => {
		if (error?.response?.data?.message === 'Please login again') {
			window.location.href = '/';
		} else return Promise.reject(error);
	}
);

export default client;
