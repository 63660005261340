import React, { useState, Fragment } from "react";
import styles from "./Productmenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ArchiveIcon from "../../../assets/images/Icons/archive.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import DeleteIcon from "../../../assets/images/Icons/delete.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
import LinkOffIcon from "../../../assets/images/Icons/link-off.svg";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import OutlineTextInput from "../../input/OutlineTextInput";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import RefundModal from "../../ModalContent/RefundModal/RefundModal";

type MenuProps = {
  productId: number;
  fetchProducts: () => void;
  status: string;
};
const ProductMenu = ({ productId, fetchProducts, status }: MenuProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [referenceData, setReferenceData] = useState<any>();
  const [menuOption, setMenuOption] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  //   const viewTransactionDetails = async (e: any) => {
  //     e.preventDefault();
  //     try {
  //       const data: any = await client.post(
  //         `/subsidiary/dashboard/fetch/customers/orders?customerid=${customerId}`
  //       );
  //       const message = data?.data?.message;
  //       dispatch(
  //         openToastAndSetContent({
  //           toastStyles: ToastSuccessStyles,
  //           toastContent: message,
  //         })
  //       );
  //     } catch (error: any) {
  //       const { message } = error?.response?.data;
  //       dispatch(
  //         openToastAndSetContent({
  //           toastStyles: ToastErrorStyles,
  //           toastContent: message,
  //         })
  //       );
  //     }
  //   };

  interface initTypes {
    amount: number;
  }

  const initProps: initTypes = {
    amount: 0,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.amount) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const archiveProduct = async (e: any, id: number) => {
    e.preventDefault();
    try {
      const data: any = await client.patch(
        `/subsidiary/dashboard/product/${
          status === "Archived" ? "unarchive" : "archive"
        }/${id}`
      );
      const message = data?.data?.message;
      fetchProducts();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const deleteProduct = async (e: any, id: number) => {
    e.preventDefault();
    try {
      const data: any = await client.delete(
        `/subsidiary/dashboard/product/delete/${id}`
      );
      const message = data?.data?.message;
      fetchProducts();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              {/* <Menus
                icon={ArchiveIcon}
                optionName={status === "Archived" ? "Unarchive" : "Archive"}
                onclick={(e: any) => {
                  archiveProduct(e, productId);
                }}
              /> */}
              <Menus
                icon={DeleteIcon}
                optionName={"Delete"}
                onclick={(e: any) => {
                  deleteProduct(e, productId);
                }}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Refund customer</div>
              <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className={styles.businessForm}>
            <form className={styles.form}>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="amount"
                inputLabel="Amount"
                inputValue={inputs.amount}
              />
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            {disableFunction() ? (
              <div className={styles.deactivate}>Create new link</div>
            ) : (
              <div className={styles.notClickable}>Create new link</div>
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ProductMenu;
