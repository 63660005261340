import React, { useEffect, useState } from 'react';
import Settlements from '../Settlements';
import styles from './SettlementTransactions.module.scss';
import { Divider } from "antd";
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { ToastErrorStyles, ToastSuccessStyles } from '../../../constants/toastStyles';
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import dayjs, { Dayjs } from 'dayjs';
import useApi from '../../../hooks/useApi';
import settlements from '../../../api/settlements';
import moment from 'moment';
import AllTransactions from '../../Transactions/Transactions';
import { numberWithCommas } from '../../../utils/formatNumber';

function SettlementTransactions({ closeModal, settlementType, data, cb }: any) {
	const dispatch = useDispatch();
	const [settlement, setSettlement] = useState<any>();
	const allSettlementApi = useApi(settlements.getSettlementTransactions);
	const transactions = allSettlementApi?.data?.data || {}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const [payment, setPayment] = useState('');
	const [transaction, setTransaction] = useState('');
	const [email, setEmail] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
		setPayment('');
		setTransaction('');
		setEmail('');
	};

	// useEffect(() => {
	// 	if (eventDate === 'today') {
	// 		setFromDate(dateNow);
	// 		setToDate(dateNow);
	// 	} else if (eventDate === 'last7days') {
	// 		setFromDate(sevenDaysAgo);
	// 		setToDate(dateNow);
	// 	} else if (eventDate === 'last30days') {
	// 		setFromDate(thirtyDaysAgo);
	// 		setToDate(dateNow);
	// 	} else if (eventDate === 'oneyear') {
	// 		setFromDate(startOfYear);
	// 		setToDate(endOfYear);
	// 	} else {
	// 		setFromDate('');
	// 		setToDate('');
	// 	}
	// }, [eventDate]);

	const filteredArray = [
		{
			name: 'Payment Reference',
			value: payment,
			setValue: setPayment,
		},
		{
			name: 'Transaction Reference',
			value: transaction,
			setValue: setTransaction,
		},
		{
			name: 'Email Address',
			value: email,
			setValue: setEmail,
		},
	];

	const fetchSettlement = async () => {
		try {
			// console.log({ data })
			dispatch(openLoader());
			const fromdate = moment(data?.finalSettlementDate).format('yyyy-MM-DD') + " 00:00:00";
			const todate = moment(data?.finalSettlementDate).format('yyyy-MM-DD') + " 23:59:59"
			const todaydate = moment(new Date()).format('yyyy-MM-DD') + " 23:59:59";
			let query = `subsidiaryId=${data?.subsidiaryId}&status=${data?.settlementStatus}&currency=${data?.currency}&fromdate=${fromdate}&todate=${todate}&size=${rowsPerPage}&page=${pageNumber}`;
			if (settlementType) query = `settlementId=${data?.id}&fromdate=2024-05-01&todate=${todaydate}&currency=${data?.currency}`;
			allSettlementApi.request(query);
			// setSettlement(res.data.data);
			// setBearer(false);
			// dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};
	// console.log({ transactions })
	const fetchSettlementCB = async () => {
		closeModal && closeModal();
		await fetchSettlement();
	}
	useEffect(() => {
		fetchSettlement();
	}, [bearer, pageNumber, rowsPerPage, status]);

	useEffect(() => {
		setPageNumber(settlement?.page || 1);
		setPreviousPage(settlement?.page - 1);
		setNextPage(settlement?.page + 1);
	}, [settlement]);
	return (
		<div className={styles.tabContainer}>
			<AllTransactions eTransactions={transactions} cb={fetchSettlementCB} />
		</div>
	);
}

export default SettlementTransactions;
