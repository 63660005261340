import React, { useEffect, useState } from 'react';
import styles from './Virtualwalletmodal.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';

const VirtualWalletModal = ({ closeModal, fetchVirtualWallets }: any) => {
	const dispatch = useDispatch();
	interface initTypes {
		// first_name: string;
		// email: string;
		// last_name: string;
		// mobile_number: string;
		alias?: string | undefined;
	}

	const initProps: initTypes = {
		// first_name: "",
		// email: "",
		// last_name: "",
		// mobile_number: "+234",
		alias: undefined,
	};
	const [inputs, setInputs] = React.useState(initProps);
	const [subb_id, setsubb_id] = useState('')
	//get current subsidiary Id
	const { activeSubsidiary } = useSelector((state) => state.subsidiaryInfoReducer);
	 

	// const disableFunction = () => {
	// 	if (
	// 		// inputs.first_name &&
	// 		// inputs.email &&
	// 		// inputs.last_name &&
	// 		// inputs.mobile_number &&
	// 		inputs?.bvn?.length === 11
	// 	) {
	// 		return true;
	// 	}
	// };

	const disableFunction = () => {
		return inputs?.alias?.trim() !== '';
	};
	
	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	useEffect(() => {
	if (activeSubsidiary) {
		setsubb_id(activeSubsidiary.id);
	}
	}, [activeSubsidiary]); 

	  
	const createVirtualWallet = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.post(
				'/subsidiary/virtual-account/generate',
				inputs,
				{
					headers: {
						'subb_id': subb_id,
					},
				}
			);

			const message = data?.data?.message;
			fetchVirtualWallets();
			dispatch(closeLoader());

			closeModal();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message || 'An error occurred'; 
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		createVirtualWallet();
	};
	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>New virtual account</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			<div className={styles.businessForm}>
				<form onSubmit={handleSubmit} className={styles.form}>
					{/* <OutlineTextInput
            handleChange={updateProps}
            inputName="first_name"
            inputLabel="First name"
            inputValue={inputs.first_name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="last_name"
            inputLabel="Last name"
            inputValue={inputs.last_name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="email"
            inputLabel="Email address"
            inputValue={inputs.email}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="mobile_number"
            inputLabel="Mobile Number"
            inputValue={inputs.mobile_number}
          /> */}

					<OutlineTextInput
						handleChange={updateProps}
						inputName='alias'
						inputLabel='Account Alias'
						inputValue={inputs.alias}
						inputType='string'
					/>
				</form>
			</div>
			<div className={styles.modalFooter}>
				<div className={styles.cancel} onClick={closeModal}>
					Cancel
				</div>
				{disableFunction() ? (
					<div className={styles.deactivate} onClick={handleSubmit}>
						Create new wallet
					</div>
				) : (
					<div className={styles.notClickable}>Create new wallet</div>
				)}
			</div>
		</div>
	);
};

export default VirtualWalletModal;
