import React from "react";

export interface VirtualWalletsData {
  accountName: string;
  accountNumber: string;
  bankName: string;
  alias: string;
}

export const ColumnVirtualWallets = [
  {
    Header: "Account Name",
    accessor: "accountName",
  },
  {
    Header: "Account Number",
    accessor: "accountNumber",
  },
  {
    Header: "Bank Name",
    accessor: "bankName",
  },
  {
    Header: "Account Alias",
    accessor: "alias",
  },
];
