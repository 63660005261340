export function getEnvironmentConfig(x: string) {
  const host = window.location.host;
  let env = "PROD"
  if (host.includes(".enterprise.transactpay.ai")) env = "ENTERPRISE"
  if (host.includes(".dev.transactpay.ai") || host.includes("localhost")) env = "DEV";
  const basename = "REACT_APP_"
  return process.env[basename + env + "_" + x];
}



export function getEnvironment() {
  const host = window.location.host;
  let env = !host.includes(".dev.transactpay.ai") && !host.includes("localhost") ? "PROD" : "DEV";
  return env;
}