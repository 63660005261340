import React from 'react';
import styles from './OtherBalanceCard.module.scss';
import { Link } from 'react-router-dom';
// import TimerIcon from '../../assets/images/Icons/timer.svg';
// import { ReactComponent as ArrowIncrease } from '../../assets/images/Icons/trending-increase.svg';
// import { ReactComponent as ArrowDecrease } from '../../assets/images/Icons/trending-decrease.svg';
import { numberWithCommas } from '../../utils/formatNumber';

type CardProps = {
	transactionType?: string;
	percentage?: number;
	amount?: number;
	currency?: string;
	icon?: string;
	link?: string;
	linkName?: string;
	count?: boolean;
};
const Card = ({
	transactionType,
	percentage,
	amount,
	icon,
	linkName,
	link,
	currency = "USD",
	count = false,
}: CardProps) => {
	return (
		<div className={styles.card}>
			<div className={styles.cardContent}>
				<div className={styles.cardCount}>{transactionType}</div>
				{/* {percentage && (
					<div className={styles.cardAmountNgn}>
						{percentage && percentage}%
						<div className={styles.doubleZero}>00</div>
					</div>
				)} */}

				<div className={styles.cardAmountNgn}>
					{!count && <span>{currency}</span>}
					{!count && amount && amount > 0 && numberWithCommas(amount)}
					{count && amount && amount > 0 && amount}

					{/* <div className={styles.doubleZero}>00</div> */}
				</div>

				<div className={styles.linkswrapper}>
					<Link to={`${link}`} className={styles.links}>
						{linkName}
					</Link>
				</div>
			</div>
			<div>
				<img src={icon} alt='timer' height={48} width={48} />
			</div>
		</div>
	);
};

export default Card;
