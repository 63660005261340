import React from 'react';

export interface RefundData {
	date: string;
	amount: string;
	customerId: string;
	transactionReference: string;
	status: any;
	action: any;
}

export const ColumnRefund = [
	{
		Header: 'Amount',
		accessor: 'amount',
	},
	{
		Header: 'Customer ID',
		accessor: 'customerId',
	},
	{
		Header: 'Transaction reference',
		accessor: 'transactionReference',
	},
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
