import React from 'react';

export interface SettlementsData {
	subsidiaryName: string;
	transactionAmount: string;
	currency: string;
	fee: string;
	settlementStatus: React.ReactNode;
	action: React.ReactNode;
}

export const ColumnSettlements = [
	{
		Header: 'Business name',
		accessor: 'subsidiaryName',
	},
	{
		Header: 'Amount',
		accessor: 'transactionAmount',
	},
	{
		Header: 'Settlement Fee',
		accessor: 'fee',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Status',
		accessor: 'settlementStatus',
	},
	{
		Header: '',
		accessor: 'action',
	},
];


export interface SettlementGroupedData {
	subsidiaryName: string;
	currency: string;
	finalSettlementDate: string;
	settlementAccount: string;
	settlementAmount: string;
	settlementBankCode: string;
	settlementBankName: string;
	settlementDestination: string;
	transactionCount: number;
	settlementStatus: React.ReactNode;
	action: React.ReactNode;
}

export const ColumnSettlementGrouped = [
	{
		Header: 'Settlement Amount',
		accessor: 'settlementAmount',
	},
	{
		Header: 'Destination',
		accessor: 'settlementDestination',
	},
	{
		Header: 'Destination Account',
		accessor: 'settlementAccount',
	},
	{
		Header: 'Settlement Date',
		accessor: 'finalSettlementDate',
	},
	{
		Header: 'Status',
		accessor: 'settlementStatus',
	},
	{
		Header: 'Transaction Count',
		accessor: 'transactionCount',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
