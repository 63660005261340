import { SAVE_SUBSIDIARY_INFO, SET_ACTIVE_SUBSIDIARY } from "../../actions/constants";

const initialSubsidiaryState = {
  subsidiaryInfo: {},
  activeSubsidiary: {},
};

export const subsidiaryInfoReducer = (state = initialSubsidiaryState, action: any) => {
  switch (action.type) {
    case SAVE_SUBSIDIARY_INFO: {
      return {
        ...state,
        subsidiaryInfo: { ...action.subsidiaryInfo },
      };
    }
    case SET_ACTIVE_SUBSIDIARY: {
      return {
        ...state,
        activeSubsidiary: { ...action.activeSubsidiary },
      };
    }
    default: {
      return state;
    }
  }
};

export default subsidiaryInfoReducer;
