const initialOnboardLevelState = {
	onboardLevelInfo: {},
	onBoardStatus: false,
	onBoardRoute: false,
	started: false,
};

export const onboardLevelInfoReducer = (
	state = initialOnboardLevelState,
	action
) => {
	switch (action.type) {
		case 'SAVE_ONBOARDLEVEL_INFO': {
			return {
				...state,
				onboardLevelInfo: { ...action.onboardLevelInfo },
			};
		}
		case 'SAVE_ONBOARDSTATUS_INFO': {
			return {
				...state,
				onBoardStatus: action.onBoardStatus,
			};
		}
		case 'SAVE_ONBOARDROUTE_INFO': {
			return {
				...state,
				onBoardRoute: action.route,
			};
		}
		case 'SAVE_STARTED': {
			return {
				...state,
				started: action.start,
			};
		}
		default: {
			return state;
		}
	}
};

export default onboardLevelInfoReducer;
