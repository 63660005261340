import React, { useState, useEffect } from 'react';
import styles from './BankDetails.module.scss';
import arrow from '../../../assets/IconsMerchant/Stroke 2.svg';
import { useHistory } from 'react-router-dom';
import { Divider, Form, Input, Select } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { saveOnboardLevelInfo } from '../../../redux/actions/onboardlevel/onboardLevelAction';
import { getOnboardingPercentagesAction } from '../../../redux/actions/authentication/authentication';

const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */
interface bankType {
	id: number;
	name: string;
	countryId: number;
	bankCode: string;
	isMicrofinance: null | string;
	isMortgage: null | string;
}
function BankDetails() {
	const history = useHistory();
	const dispatch = useDispatch();
	const [banks, setBank] = useState<bankType[]>([]);
	const [bankInfo, setBankInfo] = useState<any>([]);
	const [formValues, setFormValues] = useState<any>({});
	const [resolveData, setResolvedData] = React.useState<any>();

	const [form] = Form.useForm();
	useEffect(() => {
		form.setFieldValue('bankId', banks && banks?.filter((item) => item?.name === bankInfo[0]?.bankName)[0]?.id);
		console.log({ bankInfo })
		form.setFieldValue('accountNumber', bankInfo[0]?.accountNumber);
		form.setFieldValue('accountName', bankInfo[0]?.accountName);
		setResolvedData({
			data: {
				accountName: bankInfo[0]?.accountName || '',
				accountNumber: bankInfo[0]?.accountNumber,
				bankCode: '',
				bankId: bankInfo[0]?.id,
				amount: 0,
				description: '',
				category: '',
				payoutReference: '',
				creditCurrency: '',
				debitCurrency: '',
				beneficiayAddress: '',
			},
			status: '',
			statusCode: '',
			message: '',
		});
	}, [bankInfo, banks]);

	const INITIALVALUES = {
		bankId: {
			value: '',
			label: `...select your bank...`,
		},
		accountNumber: '',
		accountType: {
			value: `Savings`,
			label: `Savings`,
		},
		accountName: '',
	};

	const getBank = () => {
		dispatch(openLoader());
		client
			.get(`/subsidiary/dashboard/banks`)
			.then((data: any) => {
				setBank(data?.data?.banks);
			})
			.catch((error: any) => {
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}).finally(() => {
				dispatch(closeLoader());
			})
	};
	const getBankInfo = () => {
		dispatch(openLoader());
		client
			.get(`/subsidiary/dashboard/onboarding/bank/view`)
			.then((data: any) => {
				setBankInfo(data?.data?.data);
			})
			.catch((error: any) => {
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}).finally(() => {
				dispatch(closeLoader());
			})
	};
	useEffect(() => {
		getBank();
		getBankInfo();
	}, []);
	const resolveAccount = async () => {
		if (!(formValues?.accountNumber && formValues?.bankId !== '')) return;
		const bank: any = banks?.filter((item) => item?.id === Number(formValues?.bankId))[0]
		// console.log({ bank, x: formValues?.bankId })
		dispatch(openLoader());
		try {
			// console.log({ formValues })
			const data: any = await client.post(
				'/subsidiary/payout/account/resolve',
				{
					accountNumber: formValues?.accountNumber,
					bankCode: bank?.bankCode,
				}
			);
			setResolvedData(data?.data);
			form.setFieldValue('accountName', data?.data?.data?.accountName);
			const message = data?.data?.message;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const { message } = error?.response?.data ?? { message: error.message };
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};
	const updatedHandler = (values: any) => {
		dispatch(openLoader());
		client.post(`/subsidiary/dashboard/onboarding/bank/details`, {
			...values,
			accountType: values?.accountType?.value ?? values.accountType ?? "Savings",
			accountName: resolveData?.data?.accountName,
			bankId: values?.bankId
		}).then(async (res: any) => {
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastContent: res.data.message,
					toastStyles: ToastSuccessStyles,
				})
			);
			await getOnboardingPercentagesAction(dispatch);
			history.push('/dashboard/get-Started/onboarding');
		}).catch((error: any) => {
			dispatch(closeLoader());
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		});
	};

	const type = [
		{
			name: 'Savings',
		},
		{
			name: 'Current',
		},
	];
	useEffect(() => {
		if (formValues?.accountNumber?.length >= 10 && formValues?.bankId !== '') resolveAccount();
	}, [formValues?.accountNumber, formValues?.bankId]);


	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapperInner}>
				<div
					onClick={() => history.push('/dashboard/get-Started/onboarding')}
					className={styles.wrapperInnerLeft}>
					<img src={arrow} alt='' />
					<p>Get started</p>
				</div>
				<div className={styles.wrapperInnerRight}>
					<h2 className={styles.wrapperh2}>Bank details</h2>
					<p className={styles.wrapperp}>
						This is the primary bank account we send your settlements to.
					</p>
					<div className={styles.inputwrapper}>
						<Form
							form={form}
							initialValues={INITIALVALUES}
							layout='vertical'
							name='nest-messages'
							onFinish={updatedHandler}
							onValuesChange={(_, values) => setFormValues(values)}
							validateMessages={validateMessages}>
							{/* <Form.Item
								style={{ flex: 1 }}
								name='accountType'
								label='Account type'
								className='selectOptions'
								rules={[{ required: true }]}>
								<Select
									defaultValue='yes'
									placeholder='Account type'
									allowClear>
									{type?.length &&
										type?.map(({ name }, i) => (
											<Option key={i} value={name}>
												{name}
											</Option>
										))}
								</Select>
							</Form.Item> */}

							<Form.Item
								style={{ flex: 1, position: 'relative' }}
								name='bankId'
								label='Bank Name'
								className='selectOptions'
								rules={[{ required: true }]}>
								<select
									className={styles.selectDropdown}
									placeholder='Bank Name'>
									<option value={''}>Select a bank</option>
									{banks?.length &&
										banks?.map(({ name, id, bankCode }, i) => (
											<option key={i} value={id}>
												{name}
											</option>
										))}
								</select>
								{/* <p style={{ color: 'green' }}>{bankInfo[0]?.bankName}</p> */}

								{/* <div
									style={{
										padding: 100,
										height: 1000,
										background: '#eee',
										position: 'relative',
									}}
									id='area'>
									<Select
										defaultValue='Select'
										getPopupContainer={(trigger) => trigger.parentNode}
										placeholder='Bank Name'
										allowClear>
										{bank?.length &&
											bank?.map(({ name, id }, i) => (
												<Option key={i} value={id}>
													{name}
												</Option>
											))}
									</Select>
								</div> */}
							</Form.Item>

							<Form.Item
								style={{ flex: 1 }}
								name='accountNumber'
								label='Account number'
								rules={[{ required: true }]}>
								<Input
									style={{ borderRadius: '7px' }}
									className={styles.input}
								/>
							</Form.Item>
							<p className={styles.accountNameInput}>{resolveData?.data?.accountName}</p>
							{/* <Form.Item
								style={{ flex: 1 }}
								name='accountName'
								label='Account name'
								rules={[{ required: true }]}>
								<Input
									style={{ borderRadius: '7px' }}
									className={styles.input}
									disabled
								/>
							</Form.Item> */}

							<Divider />

							<div className={styles.buttonflex}>
								<button
									onClick={() =>
										history.push('/dashboard/get-Started/onboarding')
									}
									className={styles.cancel}>
									Cancel
								</button>
								<Form.Item shouldUpdate>
									{() => (
										<button
											disabled={
												resolveData && resolveData?.data?.accountName === ''
													? true
													: false
											}
											type='submit'
											className={styles.submitHandler}>
											Save
										</button>
									)}
								</Form.Item>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BankDetails;
