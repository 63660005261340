import { useState, useEffect } from "react";
import { Avatar, Badge, Dropdown, Menu } from "antd";
import styles from "./Navheading.module.scss";
import { CaretDownOutlined, MenuFoldOutlined, MenuUnfoldOutlined, } from "@ant-design/icons";
import Switch from "react-switch";
import { ReactComponent as BellIcon } from "../../assets/images/Icons/bell.svg";
import { useSelector, useDispatch } from "react-redux";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles, ToastSuccessStyles, } from "../../constants/toastStyles";
import moment from "moment";
import { saveMeInfo } from "../../redux/actions/me/meActions";
import { randnumber } from "../../utils/commonHelper";
import BackArrow from "../back/BackArrow";
import { useHistory, useLocation } from "react-router-dom";

interface propType {
  collapse: boolean;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavBarHeading = ({ collapse, setCollapse }: propType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split("/");
  const routeName = path[path.length - 1]
  // console.log({ path, routeName })

  const me = useSelector((state) => state.meReducer);
  const envronment_detail = me?.meInfo?.envronmentDetail;
  const [notification, setNotifications] = useState([]);
  const [checked, setChecked] = useState<boolean>(
    envronment_detail?.name?.toLowerCase() == "live"
  );
  const handleChecked = () => {
    toggleSwitch();
  };

  const [dropdown, setDropdown] = useState(false);
  const handleClickAway = () => {
    setDropdown(false);
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const getMe = async () => {
    try {
      const data: any = await client.get("/subsidiary/dashboard/me");
      setNotifications(data?.data?.notifications);
      dispatch(saveMeInfo(data?.data));
      setChecked(data?.data?.envronmentDetail?.name === "Live");
    } catch (error: any) {
      console.log({ error })
      if (error?.response?.status === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  const toggleSwitch = async () => {
    try {
      const data: any = await client.patch(
        "/subsidiary/dashboard/environment/toggle",
        {
          environmentId: checked ? 1 : 2,
          SetDefault: true,
        }
      );
      dispatch(
        openToastAndSetContent({
          toastContent: data?.data?.message,
          toastStyles: ToastSuccessStyles,
        })
      );
      setTimeout(() => {
        setChecked(!checked)
        window.location.reload();
      }, 200);
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  const { navbarRoute } = useSelector((state) => state.navbarReducer);
  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  const signOutHandler = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const subsidiary_details = subsidiaryInfo?.data?.subsidiaryDetails;
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (<p onClick={signOutHandler} style={{ width: "fit-content", height:'fit-content', paddingTop: "4px" }}> Log Out </p>),
        },
      ]}
    />
  );
  const menuBell = (
    <Menu
      items={[
        {
          key: randnumber(10),
          label: (
            <div
              style={{
                maxWidth: "500px",
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
              }}>
              {notification?.length > 0 ? (
                notification?.map((item: any) => (
                  <div
                    key={randnumber(10)}
                    style={{
                      borderBottom: "1px solid #ededed",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                    }}
                  >
                    <div className={styles.notificationTop}>
                      <div className={styles.messageFrom}>
                        {item?.messageFrom}
                      </div>
                      <div className={styles.date}>
                        {moment(item?.dateCreated, "YYYYMMDD").fromNow()}
                      </div>
                    </div>
                    <div key={item?.id} className={styles.messageText}>
                      {item?.messageText}
                    </div>
                  </div>
                ))
              ) : (
                <p
                  style={{
                    maxWidth: "500px",
                    paddingTop: "20px",
                    color: "black",
                  }}
                >
                  No New Notification Yet
                </p>
              )}
            </div>
          ),
        },
      ]}
    />
  );

  const Image =
    "https://i.ibb.co/fH4x0Xk/360-F-346936114-Rax-E6-OQogebg-AWTal-E1myse-Y1-Hbb5q-PM.jpg";

  return (
    <div className={styles.container}>
      <div
        className={styles.wrapper}
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        //   width: "100%",
        // }}
      >
        {/* <div className='mx-2 flex items-center'>
          <Input
            placeholder='Search'
            className={`w-[250px] rounded-full ${
              clickEvent ? 'flex' : 'hidden'
            } ease-in-out transition-all `}
          />
          <div
            onClick={() => setClickEvent(!clickEvent)}
            className='ml-[-20px] z-50 cursor-pointer '>
            <img src={Search} alt='' />
          </div>
        </div> */}
        <div style={{ display: "flex", alignItems: "center",width:'fit-content', maxHeight:'40px' }}>
          <div
            style={{ cursor: "pointer", marginRight: "20px", padding: '20px 0px' }}
            onClick={() => setCollapse(!collapse)}
          >
            {collapse ? (
              <MenuUnfoldOutlined
                style={{ fontSize: "20px", color: "#000000" }}
              />
            ) : (
              <MenuFoldOutlined style={{ fontSize: "20px", color: "#000000" }} />
            )}
          </div>
          <div style={{ fontSize: "17px", padding: '20px 0px' }} className={styles.navigationBack}>
            <BackArrow />  <div>{routeName.toUpperCase()}</div>
          </div>
        </div>

        <div style={{ display: "flex",width:'fit-content', maxHeight:'40px'}}>
          <div className={styles.switchStatement}>
            <p style={{ color: "#757575", paddingTop: "15px", }} className={styles.switchText}>
              Test
            </p>
            <div style={{ margin: "0 10px", paddingTop: "20px", }}>
              <Switch
                checked={checked}
                onChange={handleChecked}
                offColor="#757575"
                height={24}
                width={40}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#8E173E"
              />
            </div>
            <p style={{ color: "#8E173E", paddingTop: "15px", }} className={styles.switchText}>
              Live
            </p>
          </div>
          <Dropdown overlay={menuBell} placement="bottom" overlayClassName="drop">
            <div
              style={{
                display: "flex",
                margin: "0 28px",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <Badge count={notification?.length} offset={[10, 0]}>
                <BellIcon />
              </Badge>
            </div>
          </Dropdown>

          <Dropdown
            overlay={menu}
            placement="bottom"
            overlayClassName="drop"
            arrow
          >
            <div
              style={{
                margin: "0 4px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Avatar
                size="small"
                src={
                  subsidiary_details?.subsidiaries[0]?.subsidiaryLogo || Image
                }
                style={{
                  color: "#fffff",
                  background: "#8E173E",
                  marginRight: "16px",
                  cursor: "pointer",
                }}
              />
              <CaretDownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>

      {!checked && (<span className={styles.banner}> <p>You are in test mode. Live mode can be enabled at the top right corner of this dashboard.</p> </span>)}
    </div>
  );
};
// onClick={logOutHandler}
