import React, { useEffect } from "react";
import styles from "./Role.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import SelectDropdown from "../../input/SelectDropdown";
import Select from "react-select";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const CreateRole = ({ closeModal, fetchPaymentLink, allPermissions, roleDetails }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    name: string;
    description: string;
  }

  const initProps: initTypes = {
    name: roleDetails?.name ?? "",
    description: roleDetails?.description ?? "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.name && inputs.description && getPermissions()?.length > 0) {
      return true;
    }
  };

  const IsPermissionChecked = (x: any) => {
    return selectedPermissions[x.value];
  };

  const getPermissions = () => {
    const lists = [];
    for (const item in selectedPermissions) {
      if (selectedPermissions[item]) lists.push(item)
    }
    return lists;
  };

  const mapRolePermissions = () => {
    roleDetails?.permission?.map((x: any) => {
      selectedPermissions[x.id] = true
    })
    setSelectedPermissions({ ...selectedPermissions })
  };

  const toggleAddPermission = (x: any) => {
    setSelectedPermissions({ ...selectedPermissions, [x.value]: !selectedPermissions[x.value] })
  };

  // console.log({ roleDetails })

  const [selectedPermissions, setSelectedPermissions] = React.useState<any>({});

  let permissionOptions = allPermissions?.map(function (permission: any) {
    return { value: permission?.id, label: permission?.name };
  });

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  useEffect(() => {
    mapRolePermissions();
  }, [roleDetails])

  const createPaymentLink = async () => {
    try {
      var action = roleDetails ? "edit" : "create"
      const data: any = await client.post(`subsidiary/dashboard/role/${action}`, {
        ...inputs,
        permissions: getPermissions(),
        roleId: roleDetails?.id,
      });

      const message = data?.data?.message;
      fetchPaymentLink();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>{!roleDetails ? "New role" : "Edit Role"}</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Role name"
            inputValue={inputs.name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
          />
          <div>
            <label className={styles.inputLabel}>Permissions</label>
            {/* <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={permissionOptions}
              isMulti
              // styles={customStyles}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              className={styles.select}
            /> */}
            <div className={styles.permissionWrapper}>
              {
                permissionOptions.map((x: any) => (
                  <p onClick={() => toggleAddPermission(x)}>
                    {IsPermissionChecked(x) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    &nbsp;{x.label}
                  </p>
                ))
              }
            </div>
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            {roleDetails ? "Edit role" : "Create role"}
          </div>
        ) : (
          <div className={styles.notClickable}>{roleDetails ? "Edit role" : "Create role"}</div>
        )}
      </div>
    </div>
  );
};

export default CreateRole;
