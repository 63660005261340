import React, { useState, useEffect } from 'react';
import styles from './Webhookcard.module.scss';
import { ReactComponent as ContentCopy } from '../../assets/IconsMerchant/u_copy.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import client from '../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import { closeLoader, openLoader } from '../../redux/actions/loader/loaderActions';

type WebhookCardProps = {
	urlName?: string;
	urlContent: string;
	post?: boolean;
	setBearer: React.Dispatch<React.SetStateAction<boolean>>;
};

const WebhookCard = ({
	urlName,
	urlContent,
	post = false,
	setBearer,
}: WebhookCardProps) => {
	const [copied, setCopied] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		setInputValue(urlContent);
	}, [urlContent]);

	if (copied) {
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	}
	function isValidURL() {
		if (
			/^(http(s?):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
				inputValue
			)
		) {
			return true;
		} else {
			return false;
		}
	}

	function maskString(str: string, maskChar = '*') {
		if (!str) return "";
		// Validate input parameters
		if (typeof str !== 'string' || typeof maskChar !== 'string' || maskChar.length !== 1) {
			throw new Error('Invalid input parameters.');
		}

		// Leave the first 5 characters unmasked and mask the rest
		const leave = 14;
		const unmaskedPart = str.slice(0, leave);
		const maskedPart = str.slice(leave).split('').map(char => maskChar).join('');
		return unmaskedPart + maskedPart;
	}

	// This code is contributed by Rahul Chauhan

	const clickHandler = () => {
		dispatch(openLoader())
		client
			.post('/subsidiary/static/account/default/webhook', {
				defaultWebhook: inputValue,
			})
			.then((data: any) => {
				setBearer(true);
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
			})
			.catch((error: any) => {
				const message = error?.response?.data?.message ?? "Something went wrong while updating your url";
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}).finally(() => {
				dispatch(closeLoader())
			});
	};

	return (
		<div className={styles.webhookUrl}>
			<div className={styles.urlName}>{urlName}</div>
			{post ? (
				<div className={styles.urlContent}>
					<input
						className={styles.urlInput}
						type='text'
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
					/>
				</div>
			) : (
				<div className={styles.urlContent}>
					{maskString(urlContent && urlContent?.length > 100
						? urlContent?.substring(0, 40) + '...'
						: urlContent, "x")}
				</div>
			)}

			<div className={styles.flexbutton}>
				<CopyToClipboard text={urlContent} onCopy={() => setCopied(true)}>
					<div className={styles.copy}>
						{copied ? (
							<div>Copied!</div>
						) : (
							<div>
								<ContentCopy /> &nbsp;&nbsp; Copy
							</div>
						)}
					</div>
				</CopyToClipboard>
				{post && (
					<div style={{ marginLeft: '10px' }}>
						<button
							disabled={isValidURL() ? false : true}
							onClick={clickHandler}
							className={styles.addweb}>
							Add webUrl
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default WebhookCard;
