import React, { useState, useEffect } from 'react';
import BankDetails from './BankDetails/BankDetails';
import styles from './compliance.module.scss';
import Information from './Information/Information';
import complianceApi from '../../api/compliance';
import useApi from '../../hooks/useApi';

import Profile from './Profile/Profile';
import Kyc from './Kyc/Kyc';

const Compliance = () => {
	const [selection, setSelection] = useState('Profile');

	return (
		<div>
			<div className={styles.tabWrapper}>
				<div
					className={
						selection === 'Profile' ? styles.selectionActive : styles.selection
					}
					onClick={() => setSelection('Profile')}>
					Profile
				</div>
				<div
					className={
						selection === 'Bank Details'
							? styles.selectionActive
							: styles.selection
					}
					onClick={() => setSelection('Bank Details')}>
					Bank Details
				</div>
				<div
					className={
						selection === 'Information'
							? styles.selectionActive
							: styles.selection
					}
					onClick={() => setSelection('Information')}>
					Director’s Information
				</div>
				<div
					className={
						selection === 'Kyc' ? styles.selectionActive : styles.selection
					}
					onClick={() => setSelection('Kyc')}>
					KYC Details
				</div>
			</div>
			<div className={styles.selectionwrapper}>
				{selection === 'Profile' && (
					<div className={styles.selectflex}>
						<p className={styles.selectflex_p}>Profile</p>
						<Profile selection={selection} setSelection={setSelection} />
					</div>
				)}
				{selection === 'Bank Details' && (
					<div className={styles.selectflex}>
						<p className={styles.selectflex_p}>Bank Details</p>
						<BankDetails selection={selection} setSelection={setSelection} />
					</div>
				)}
				{selection === 'Information' && (
					<div className={styles.selectflex}>
						<p className={styles.selectflex_p}>Information</p>
						<Information selection={selection} setSelection={setSelection} />
					</div>
				)}
				{selection === 'Kyc' && (
					<div className={styles.selectflex}>
						<p className={styles.selectflex_p}>Kyc</p>
						<Kyc selection={selection} setSelection={setSelection} />
					</div>
				)}
			</div>
		</div>
	);
};

export default Compliance;
