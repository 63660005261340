import React, { useState } from 'react';
import styles from './FilteredUsage.module.scss';
import { Divider, MenuProps } from 'antd';
import {
	Menu,
	Checkbox,
	Radio,
	Space,
	Collapse,
	DatePicker,
	Input,
} from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';

import moment from 'moment';

const { SubMenu } = Menu;
const { Search } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface menuTypes {
	id: number;
	name: string;
	value: string;
}

function FilteredUsage({
	setDropdown,
	filteredArray,
	setBearer,
	clearHandler,
	setFromDate,
	setToDate,
}: {
	setDropdown: React.Dispatch<React.SetStateAction<boolean>>;
	filteredArray?: {
		name: string;
		value: any;
		setValue: any;
		selective?: any;
		id: number;
	}[];
	setBearer?: React.Dispatch<React.SetStateAction<boolean>>;
	clearHandler?: () => void;
	setFromDate?: React.Dispatch<React.SetStateAction<string>>;
	setToDate?: React.Dispatch<React.SetStateAction<string>>;
}) {
	const onChange = (
		value: DatePickerProps['value'] | RangePickerProps['value'],
		dateString: [string, string] | string
	) => {
		setFromDate?.(dateString[0]);
		setToDate?.(dateString[1]);
	};

	const applyHandler = () => {
		setDropdown(false);
		setBearer?.(true);
	};

	const formulatedKeys = () => {
		let arr = [];
		if (filteredArray)
			for (let i = 1; i <= filteredArray?.length; i++) {
				arr.push(String(i));
			}
		return arr;
	};

	return (
		<div>
			<Collapse
				style={{ width: '300px' }}
				className='bg-white '
				bordered={false}
				expandIconPosition='end'
				defaultActiveKey={formulatedKeys()}>
				{filteredArray?.map(({ name, value, selective, setValue, id }) => (
					<Panel header={`${name}`} key={id}>
						{!selective || selective?.length <= 0 ? (
							<Search
								allowClear
								onChange={(e) => setValue(e.target.value)}
								style={{ width: 267 }}
								value={value}
								className='search'
							/>
						) : (
							<Radio.Group
								onChange={(e) => setValue(e.target.value)}
								value={value}>
								<Space direction='vertical'>
									{selective?.map((item: any) => (
										<Radio value={item.id ? item.id : item.value === undefined ? item.name : item.value}>
											{item.name}
										</Radio>
									))}
								</Space>
							</Radio.Group>
						)}
					</Panel>
				))}
			</Collapse>
			<Divider style={{ margin: 0, padding: 0, marginTop: '10px' }} />
			<div
				style={{
					padding: '20px 14px',
				}}>
				<RangePicker
					style={{ width: 267 }}
					format='YYYY-MM-DD'
					onChange={onChange}
				/>
			</div>

			<div className={styles.buttonwrapper}>
				<button onClick={clearHandler} className={styles.resetFilter}>
					Reset
				</button>
				<button onClick={applyHandler} className={styles.applyFilter}>
					Apply Filter
				</button>
			</div>
		</div>
	);
}

export default FilteredUsage;
