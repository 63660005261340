import React, { useEffect, useState } from 'react';
import styles from './Emailverified.module.scss';
import Logo from '../../../assets/images/Logos/paymentgateway.png';
import { ReactComponent as AccountIcon } from '../../../assets/images/Icons/account.svg';
import { openLoader, closeLoader, } from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import client from '../../../api/client';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { saveSubsidiaryInfo } from '../../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';
import { ToastErrorStyles, ToastSuccessStyles, } from '../../../constants/toastStyles';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import showPwdImg from '../../../assets/images/Icons/show-password.svg';
import hidePwdImg from '../../../assets/images/Icons/hide-password.svg';
import { randnumber } from '../../../utils/commonHelper';

const EmailVerified = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params: any = useParams()
	const token = params.token
	const { search } = useLocation()
	const query = new URLSearchParams(search);
	const isExistingUser = query.get('isExistingUser')?.toLowerCase() == "true";
	// console.log({ params, search, query, isExistingUser })

	interface initTypes {
		token: string;
		password?: string;
	}

	const initProps: initTypes = {
		token: token,
		password: '',
	};
	const [inputs, setInputs] = React.useState(initProps);

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const [response, setResponse] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = () => {
		dispatch(openLoader());
		setIsLoading(true);
		if (!inputs.password) inputs.password = "He123@" + randnumber(4);
		client.post(`/subsidiary/dashboard/users/verify`, inputs)
			.then((data: any) => {
				setIsLoading(false);
				dispatch(closeLoader());
				dispatch(saveSubsidiaryInfo(data));

				const { status, message, token } = data?.data;
				// console.log('message', message);
				setResponse(message);
				const { accessToken } = token;
				dispatch(
					openToastAndSetContent({
						toastContent: data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
			})
			.catch((error: any) => {
				// <ErrorToast error={error} />;
				if (error?.response) {
					setIsLoading(false);
					dispatch(closeLoader());
					const { message } = error?.response?.data;
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				} else if (error?.request) {
					setIsLoading(false);
					dispatch(closeLoader());
				} else {
					setIsLoading(false);
					dispatch(closeLoader());
				}
			});
	};
	useEffect(() => {
		isExistingUser && handleSubmit();
	}, [token]);

	if (isLoading) {
		return <div />;
	}

	return (
		<div className={styles.signInBody}>
			<div className={styles.logo}>
				<img src={Logo} alt='Transactpay' />
			</div>
			{!!response && (
				<div className={styles.signinForm}>
					<div className={styles.signIn}>{response}</div>
				</div>
			)}
			{isExistingUser && <div className={styles.newAccount}>
				<div className={styles.noAccount}>Click here to sign in</div>
				<div
					className={styles.create}
					onClick={() => {
						history.push('/');
					}}>
					<AccountIcon /> Signin
				</div>
			</div>}
			{!isExistingUser && !response && (
				<div className={styles.businessForm}>
					<form onSubmit={handleSubmit} className={styles.form}>
						<h3>Enter a strong password</h3>
						<div className={styles.passwordInp}>
							<OutlineTextInput
								style={{ marginTop: '20px', width: '100%' }}
								handleChange={updateProps}
								inputName='password'
								inputLabel='Password'
								inputType={isRevealPwd ? 'text' : 'password'}
								inputValue={inputs.password}
							/>

							<img
								alt='/'
								className={styles.inputImg}
								title={isRevealPwd ? 'Hide password' : 'Show password'}
								src={isRevealPwd ? hidePwdImg : showPwdImg}
								onClick={() => setIsRevealPwd((prevState) => !prevState)}
							/>
						</div>
						<button onClick={handleSubmit} className={styles.signInButton} disabled={!inputs.token && !inputs.password}>
							Accept Invitation
						</button>
					</form>
				</div>
			)}
		</div>
	);
};

export default EmailVerified;
