import React, { useState, Fragment } from 'react';
import styles from './Settlementmenu.module.scss';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import RefundIcon from '../../../assets/images/Icons/refund-icon.svg';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import Menus from '../Menus/Menus';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { computeCustomStyles, customStyles } from '../../../constants/modalStyles';
import moment from 'moment';
import { numberWithCommas } from '../../../utils/formatNumber';
import ParentContainer from '../../ParentContainer/ParentContainer';
import StatusView from '../../StatusView/StatusView';
import SettlementTransactions from '../../../views/Settlements/SettlementTransactions/SettlementTransactions';

type MenuProps = {
	data: any;
	isTransactionSummary?: boolean
};
const SettlementMenu = ({ data, isTransactionSummary }: MenuProps) => {
	const dispatch = useDispatch();
	const [menuOption, setMenuOption] = useState(false);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	function openModal() {
		setIsOpen(true);
	}
	const handleClickAway = () => {
		setMenuOption(false);
	};
	const handleMenu = () => {
		setMenuOption(!menuOption);
	};

	return (
		<Fragment>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className={styles.menuBody}>
					<div className={styles.menuIcon} onClick={handleMenu}>
						<MenuIcon />
					</div>

					{menuOption && (
						<div className={styles.menuContent} onClick={handleClickAway}>
							<Menus
								icon={ViewIcon}
								optionName={'View details'}
								onclick={() => setIsOpen(true)}
							/>
						</div>
					)}
				</div>
			</ClickAwayListener>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={isTransactionSummary ? computeCustomStyles() : customStyles}>
				{isTransactionSummary && <SettlementTransactions data={data} />}
				{!isTransactionSummary && <div className={styles.modalBody}>
					<div className={styles.modalHeader}>
						<div className={styles.background}>
							<div className={styles.linkDetails}>Settlement Details</div>
							<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
						</div>
					</div>
					{data && (
						<div className={styles.modalContent}>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Date</div>
								<div className={styles.content}>
									{moment(data?.dateCreated).format('MMMM Do YYYY, h:mm a')}
								</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Status</div>
								<div className={styles.content}>
									<StatusView
										status={data?.settlementStatus}
										green='Completed'
										red='Failed'
										orange='Pending'
									/>
									{/* {data?.settlement_status === 'Completed' ? (
										<div className={styles.active}>Completed</div>
									) : (
										<div className={styles.processing}>N/A</div>
									)} */}
								</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Subsidiary Name</div>
								<div className={styles.content}>{data?.subsidiaryName}</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Transaction amount</div>
								<div className={styles.content}>
									{`${data?.currency} ${numberWithCommas(data?.transactionAmount)}`}
								</div>
							</div>

							<div className={styles.modalDetails}>
								<div className={styles.link}>Settlement Fee</div>
								<div className={styles.content}>{`${data?.currency} ${numberWithCommas(
									data?.fee
								)}`}</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Currency</div>
								<div className={styles.content}> {data?.currency}</div>
							</div>

							<div className={styles.modalDetails}>
								<div className={styles.link}>Reference#</div>
								<div className={styles.content}>
									{data?.transactionReference}
								</div>
							</div>
						</div>
					)}
				</div>}
			</Modal>
		</Fragment>
	);
};

export default SettlementMenu;
