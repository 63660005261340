import React, { Dispatch, SetStateAction } from 'react';
import styles from './kyc.module.scss';

import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { ReactComponent as SaveIcon } from '../../../assets/images/Icons/save.svg';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { useDispatch, useSelector } from 'react-redux';
import { formClient } from '../../../api/client';
import { useHistory } from 'react-router-dom';
import Upload from '../../../assets/IconsMerchant/u_upload.svg';

type SelectionProp = {
	selection?: string;
	setSelection: Dispatch<SetStateAction<string>>;
};
const Kyc = ({ selection, setSelection }: SelectionProp) => {
	const history = useHistory();
	interface initTypes {
		proofofaddress: string;
		id: string;
	}

	const dispatch = useDispatch();
	const initProps: initTypes = {
		proofofaddress: '',
		id: '',
	};
	const [inputs, setInputs] = React.useState(initProps);

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};
	const updateImageProps = (event: any) => {
		const newValue = event?.target?.files[0];
		const inputName = event?.target?.name;
		setInputs((prevState: any) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	let formData = new FormData();

	formData.append('proofofaddress', inputs.proofofaddress); //append the values with key, value pair
	formData.append('id', inputs.id);

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		formClient
			.post('/subsidiary/dashboard/kyc/details/submit', formData)
			.then((data: any) => {
				dispatch(closeLoader());
				setSelection('Information');
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);

				history.push('/dashboard/');
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	return (
		<div className={styles.business}>
			<div className={styles.businessCard} style={{ marginTop: '30px' }}>
				<div className={styles.businessForm}>
					<form className={styles.form}>
						<div className={styles.uploadwrapper}>
							<OutlineTextInput
								inputName='id'
								inputLabel='Means of identification'
								style={{ width: '100%', zIndex: '999' }}
								handleChange={updateImageProps}
								inputType='file'
								inputShrink
							/>
							<div className={styles.uploaddiv}>
								<div className={styles.emptydiv}></div>
								<img src={Upload} alt='' />
							</div>
						</div>
						<div className={styles.uploadwrapper}>
							<OutlineTextInput
								inputName='proofofaddress'
								style={{ width: '100%', zIndex: '999' }}
								inputLabel='Proof of address'
								handleChange={updateImageProps}
								inputType='file'
								inputShrink
							/>
							<div className={styles.uploaddiv}>
								<div className={styles.emptydiv}></div>
								<img src={Upload} alt='' />
							</div>
						</div>
					</form>
					<div
						className={styles.uploadButton}
						onClick={(e) => {
							handleSubmit(e);
						}}>
						Save and Continue
					</div>
				</div>
			</div>
		</div>
	);
};

export default Kyc;
