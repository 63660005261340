const initialSubsidiaryState = {
	meInfo: {},
};

export const meReducer = (state = initialSubsidiaryState, action) => {
	switch (action.type) {
		case 'ME': {
			return {
				...state,
				meInfo: { ...action.info },
			};
		}
		default: {
			return state;
		}
	}
};

export default meReducer;
