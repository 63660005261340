import React from "react";

export interface UsersData {
  emailAddress: string;
  fullName: string;
  mobileNumber: string;
  role: string;
  status: any;
  userId: number;
  addedByUserName: string;
  dateCreated: string;
  action: any
}

export const ColumnUsers = [
  {
    Header: "Date Created",
    accessor: "dateCreated",
  }, {
    Header: "Name",
    accessor: "fullName",
  },
  {
    Header: "Email Address",
    accessor: "emailAddress",
  },
  {
    Header: "Mobile Number",
    accessor: "mobileNumber",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Added By",
    accessor: "addedByUserName",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
