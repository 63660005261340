import { CHANGE_CURRENCY, GET_COUNTRIES, GET_CURRENCIES } from "../constants";
import { ReactNode } from "react";
import currencyApi from '../../../api/currencies';

export const changeCurrency = (currency: any) => {
  return {
    type: CHANGE_CURRENCY,
    currency,
  };
};

export const getCountriesAction = async () => {
  const res: any = await currencyApi.getCountries();
  const countries = res?.data?.data ?? []
  return {
    type: GET_COUNTRIES,
    countries,
  };
};

export const getCurrenciesAction = async () => {
  const res: any = await currencyApi.getCurrencies();
  const currencies = res?.data?.data ?? []
  return {
    type: GET_CURRENCIES,
    currencies,
  };
};
