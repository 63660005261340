import React, { useState, Fragment, useEffect } from 'react';
import styles from './Products.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import TableHeader from '../../components/TableHeader/TableHeader';
import { ColumnProducts, ProductsData } from '../../types/TablesTypes/Products';
import StatusView from '../../components/StatusView/StatusView';
import productsApi from '../../api/products';
import useApi from '../../hooks/useApi';
import { ReactComponent as TopUp } from '../../assets/images/Icons/focusadd.svg';
import { numberWithCommas } from '../../utils/formatNumber';
import { useHistory } from 'react-router-dom';
import ProductMenu from '../../components/MenuOption/ProductMenu/ProductMenu';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import FilteredUsage from '../../components/filterUsableComponent/FilteredUsage';

const PaymentLinks = () => {
	const dispatch = useDispatch();
	const [products, setProducts] = useState<any>();
	const history = useHistory();
	const [value, setValue] = useState('');
	const [tableRow, setTableRow] = useState<any[]>();
	const getProductsApi = useApi(productsApi.getProducts);

	//for filtering
	const [dropdown, setDropdown] = useState(false);
	const [fromDate, setFromDate] = useState<string>('');
	const [availability, setAvailability] = useState<boolean | null>();
	const [toDate, setToDate] = useState<string>('');
	const [bearer, setBearer] = useState(false);
	const clearHandler = () => {
		setFromDate('');
		setToDate('');
		setAvailability(null);
		setDropdown(false);
		setBearer(true);
	};
	const filteredArray = [
		{
			id: 1,
			name: 'Availability',
			value: availability,
			setValue: setAvailability,
			selective: [{ name: 'true' }, { name: 'false' }],
		},
	];

	const fetchProducts = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/subsidiary/dashboard/product/all?availability=${availability}&fromdate=${fromDate}&todate=${toDate}`
			);
			setProducts(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchProducts();
	}, [bearer]);

	const dataPaymentLinks = () => {
		const tempArr: ProductsData[] = [];
		products?.items
			?.slice(0)
			.reverse()
			.forEach((product: any, index: number) => {
				 
				tempArr.push({
					productName: (
						<div className={styles.productContent}>
							<img
								src={product?.image}
								alt={product?.name}
								width={64}
								height={64}
								className={styles.productImg}
							/>
							<div className={styles.productName}>{product?.name}</div>
						</div>
					),

					price: `NGN ${numberWithCommas(product?.price)}`,
					availability: (
						<StatusView
							status={product?.availability}
							green='In-stock'
							red='Out-of-stock'
						/>
					),
					stockCount: product?.stock,
					sold: product?.total_orders,
					revenue: `NGN ${numberWithCommas(product?.totalAmount)}`,
					action: (
						<ProductMenu
							fetchProducts={fetchProducts}
							productId={product?.id}
							status={product?.status}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataPaymentLinks());
	}, [products?.items]);
	return (
		<Fragment>
			<TableHeader
				pageName='Products'
				dataLength={products?.totalItems}
				data={products?.items}
				value={value}
				setValue={setValue}
				dropdown={dropdown}
				setDropdown={setDropdown}
				exporting={false}
				newButton={
					<div
						className={styles.createLink}
						onClick={() => {
							history.push('products/new-product');
						}}>
						<TopUp className={styles.link} />
						Add New Product
					</div>
				}
				FilterComponent={
					<>
						<FilteredUsage
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					</>
					// <div>
					// 	<div className={styles.filterSubHeading}>Status</div>
					// 	<div className={styles.filterBody}>
					// 		<div
					// 			onClick={() => {
					// 				setAvailability(true);
					// 			}}
					// 			style={{ cursor: 'pointer' }}>
					// 			In Stock
					// 		</div>
					// 		<div
					// 			onClick={() => setAvailability(false)}
					// 			style={{ cursor: 'pointer' }}>
					// 			Out of Stock
					// 		</div>
					// 	</div>
					// </div>
				}
			/>
			{getProductsApi.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					data={tableRow ? tableRow : []}
					value={value}
					columns={ColumnProducts ? ColumnProducts : []}
					emptyPlaceHolder='You do not have any products'
					total={products?.totalItems}
				/>
			)}
		</Fragment>
	);
};

export default PaymentLinks;
