export interface AuditData {
  name: string;
  role: string;
  emailAddress: string;
  action: string;
  activityType: string;
  ipAddress: string;
  dateCreated: any;
}

export const AuditColumn = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Email Address",
    accessor: "emailAddress",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Activity Type",
    accessor: "activityType",
  },
  {
    Header: "IP Address",
    accessor: "ipAddress",
  },
  {
    Header: "Date initiated",
    accessor: "dateCreated",
  },
];
