import React, { useEffect } from "react";
import styles from "./Forgotpassword.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import { useHistory } from "react-router-dom";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";

const ForgotPasswordModal = ({ closeModal }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  interface initTypes {
    identifier: string;
  }

  const initProps: initTypes = {
    identifier: "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (!!inputs.identifier) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const createPaymentLink = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        "/subsidiary/dashboard/forgot-password",
        inputs
      );

      const message = data?.data?.message;
      dispatch(closeLoader());
      history.push(`/forgot-password/${inputs.identifier}`);
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };

  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Reset password</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="identifier"
            inputLabel="Email Address"
            inputValue={inputs.identifier}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Reset password
          </div>
        ) : (
          <div className={styles.notClickable}>Reset password</div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
