import React, { useEffect } from "react";
import styles from "./Createpaymentlink.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import Select from "react-select";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";

const CreatePaymentLink = ({ closeModal, fetchPaymentLink }: any) => {
  const dispatch = useDispatch();

  const [paymentTypes, setPaymentTypes] = React.useState<any>([]);
  const [currencies, setCurrencies] = React.useState<any>([]);
  const [selectedCurrency, setSelectedCurrency] = React.useState<any>();
  const [selectedPaymentType, setSelectedPaymentType] = React.useState<any>();

  interface initTypes {
    name: string;
    description: string;
    amount: number | undefined;
    limit?: number | undefined;
    currency: string | undefined;
    redirectUrl: string;
  }

  const initProps: initTypes = {
    name: "",
    description: "",
    amount: undefined,
    limit: undefined,
    currency: undefined,
    redirectUrl: "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.name && selectedPaymentType?.value && selectedCurrency?.value) return true;
    return false;
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const fetchPaymentLinkTypes = async () => {
    try {
      const data: any = await client.get(
        "/subsidiary/dashboard/payment/link/types"
      );
      console.log(data?.data?.paymentLinkTypes)
      setPaymentTypes(data?.data?.paymentLinkTypes);
    } catch (error: any) {
      const { message } = error?.response?.data ?? {};
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response: any = await client.get(
        "/subsidiary/dashboard/service/currencies"
      );
      let currencies = response?.data?.data;
      currencies = currencies.sort((a: any, b: any) => {
        if (a.shortName === "NGN") return -1;
        if (b.shortName === "NGN") return 1;
        return 0;
      });
      setCurrencies(response?.data?.data);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  let selectedPaymentTypeOptions = paymentTypes.map((selectedPaymentType: any) => {
    let uiName = selectedPaymentType.paymentLinkName;

    if (uiName === "Single Charge") {
      uiName = "One Off"; 
    } else if (uiName === "Multiple Charge") {
      uiName = "Multiple Use";
    }
  
    return {
      value: selectedPaymentType.code,
      label: uiName,
      // label: selectedPaymentType.paymentLinkName,
    };
  });

  let currencyOptions = currencies.map(function (selectedcurrency: any) {
    return {
      value: selectedcurrency.shortName,
      label: selectedcurrency.name,
    };
  });

  const createPaymentLink = async () => {
    try {
      dispatch(openLoader())
      const data: any = await client.post(
        "/subsidiary/dashboard/payment/link/new",
        {
          ...inputs,
          paymentType: selectedPaymentType.value,
          currency: selectedCurrency.value,
        }
      );

      const message = data?.data?.message;
      fetchPaymentLink();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
    finally {
      dispatch(closeLoader())
    }
  };

  useEffect(() => {
    fetchPaymentLinkTypes();
    fetchCurrencies();
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New payment link</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Link name"
            inputValue={inputs.name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
          />

          <Select
            defaultValue={selectedCurrency}
            onChange={setSelectedCurrency}
            options={currencyOptions}
            placeholder="Currency"
            // styles={customStyles}
            // className={styles.select}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: "300px",
              }),
            }}
          />

          <Select
            defaultValue={selectedPaymentType}
            onChange={setSelectedPaymentType}
            options={selectedPaymentTypeOptions}
            placeholder="Payment Type"
            // styles={customStyles}
            // className={styles.select}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                width: "300px",
              }),
            }}
          />

          <OutlineTextInput
            notRequired={true}
            handleChange={updateProps}
            inputName="amount"
            inputLabel="Amount (Optional)"
            inputValue={inputs.amount}
          />

          <OutlineTextInput
            notRequired={true}
            handleChange={updateProps}
            inputName="limit"
            inputLabel="Limit (Optional)"
            inputValue={inputs.limit}
          />

          <OutlineTextInput
            notRequired={true}
            handleChange={updateProps}
            inputName="redirectUrl"
            inputLabel="Add Redirect Url (Optional)"
            inputValue={inputs.redirectUrl}
            placeholder="Website URL (must include http:// or https://) e.g https://www.google.com"
          />
        </form>
      </div>

      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create new link
          </div>
        ) : (
          <div className={styles.notClickable}>Create new link</div>
        )}
      </div>
    </div>
  );
};

export default CreatePaymentLink;
