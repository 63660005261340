import React from "react";

export interface CustomersData {
  customerName: string;
  emailAddress: string;
  mobileNumber: string;
  countryName: string;
  transactionCount?: string,
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnCustomers = [
  {
    Header: "Name",
    accessor: "customerName",
  },
  {
    Header: "Email",
    accessor: "emailAddress",
  },
  {
    Header: "Mobile Number",
    accessor: "mobileNumber",
  },
  {
    Header: "Country",
    accessor: "countryName",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  // {
  //   Header: "Transaction Count",
  //   accessor: "transactionCount",
  // },
  {
    Header: "Action",
    accessor: "action",
  },
];
