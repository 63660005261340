import { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";

const PublicRoute = ({ children, ...rest }: any) => {
  const access_token = sessionStorage.getItem("accessToken");
  // console.log(access_token)
  const history = useHistory();

  useEffect(() => {
    // console.log({ access_token })
    if (access_token) {
      window.location.assign("/dashboard")
    }
  }, [children])

  return (
    <Route
      {...rest}
      render={() => (!access_token ? children : <></>)}
    />
  );
};

export default PublicRoute;
