import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SignIn from '../views/Authentications/SignIn/SignIn';
import SignUp from '../views/Authentications/SignUp/SignUp';
import DashboardRoutes from './DashboardRoutes';
import PrivateRoute from './PrivateRoutes';
import ScrollToTop from '../ScrollToTop';
import PublicRoute from './PublicRoutes';
import VerifyEmail from '../views/Authentications/VerifyEmail/VerifyEmail';
import EmailVerified from '../views/Authentications/EmailVerified/EmailVerified';
import ForgotPassword from '../views/Authentications/ForgotPassword/ForgotPassword';
import TwoFaSignin from '../views/Authentications/SignIn/TwoFaSignIn';
import OnBoardingVerification from '../views/Authentications/OnBoardingVerification/OnBoardingVerification';

export default function AppRoutes() {
	return (
		<Router>
			<ScrollToTop />
			<Switch>
				<PublicRoute exact path='/'>
					<SignIn />
				</PublicRoute>
				<PublicRoute exact path='/login'>
					<Redirect to="/" />
				</PublicRoute>
				<PublicRoute exact path='/2fa-signin'>
					<TwoFaSignin />
				</PublicRoute>
				<PublicRoute exact path='/sign-up'>
					<SignUp />
				</PublicRoute>
				<PublicRoute exact path='/forgot-password/:email'>
					<ForgotPassword />
				</PublicRoute>
				<PublicRoute exact path='/verify'>
					<VerifyEmail />
				</PublicRoute>
				<PublicRoute exact path='/email-verified/:token?'>
					<EmailVerified />
				</PublicRoute>
				<PublicRoute exact path='/onboarding/:token'>
					<OnBoardingVerification />
				</PublicRoute>
				<PrivateRoute path='/dashboard'>
					<DashboardRoutes />
				</PrivateRoute>
			</Switch>
		</Router>
	);
}
