import React from "react";

export interface RefundsData {
  date: string;
  customerEmail: string;
  transactionAmount: string;
  refundAmount: string;
  reference: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnRefund = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Customer Email",
    accessor: "customerEmail",
  },
  {
    Header: "Transaction Amount",
    accessor: "transactionAmount",
  },
  {
    Header: "Refund Amount",
    accessor: "refundAmount",
  },
  {
    Header: "Reference",
    accessor: "reference",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
