const initialAuthState = {
	identifierState: '',
};

export const getIdentifierStateReducer = (state = initialAuthState, action) => {
	switch (action.type) {
		case 'GETIDENTIFIERSTATE': {
			return {
				...state,

				identifierState: action.identifierState,
			};
		}

		default: {
			return state;
		}
	}
};

export default getIdentifierStateReducer;
