import React, { useState, Fragment, useEffect } from 'react';
import styles from './Virtualwallets.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import TableHeader from '../../components/TableHeader/TableHeader';
import {
	ColumnVirtualWallets,
	VirtualWalletsData,
} from '../../types/TablesTypes/VirtualWallet';
import ExportButton from '../../components/ExportButton/ExportButton';
import virtualWalletsApi from '../../api/virtualWallets';
import useApi from '../../hooks/useApi';
import { ReactComponent as TopUp } from '../../assets/images/Icons/newwallet.svg';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import VirtualWalletModal from '../../components/ModalContent/VirtualWalletModal/VirtualWalletModal';
import Filtered from '../../components/filterComponent/Filtered';

const PaymentLinks = () => {
	const [value, setValue] = useState('');
	const [tableRow, setTableRow] = useState<any[]>();
	const getVirtualWalletsApi = useApi(virtualWalletsApi.getVirtualWallets);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [dropdown, setDropdown] = useState(false);
	const [searchBank, setSearchBank] = useState('');


	function closeModal() {
		setIsOpen(false);
	}

	const fetchVirtualWallets = async () => {
		virtualWalletsApi.getVirtualWallets()
		.then((response: any) => {
			setTableRow(dataPaymentLinks(response?.data));
		})
		.catch((error: any) => {
			console.error("Error fetching payouts:", error);
		});
	};

	useEffect(() => {
		fetchVirtualWallets();
	}, []);

	const dataPaymentLinks = (items: any) => {
		const tempArr: VirtualWalletsData[] = [];
		if (Array.isArray(items)) {
			items.slice(0).reverse().forEach((virtualWallet) => {
				tempArr.push({
					accountName: virtualWallet?.accountName || '',
					accountNumber: virtualWallet?.accountNumber || '',
					bankName: virtualWallet?.bankName || '',
					alias: virtualWallet?.alias || '',
				});
			});
		} else if (items) { 
			tempArr.push({
				accountName: items?.accountName || '',
				accountNumber: items?.accountNumber || '',
				bankName: items?.bankName || '',
				alias: items?.alias || '',
			});
		}
	
		return tempArr;
	};
	
	return (
		<Fragment>
			<TableHeader
				pageName='Virtual accounts'
				dataLength={getVirtualWalletsApi?.data?.total_items}
				data={getVirtualWalletsApi?.data?.items}
				value={value}
				setValue={setValue}
				newButton={
					<div className={styles.createLink} onClick={() => setIsOpen(true)}>
						<TopUp className={styles.link} />
						Create Virtual Wallet
					</div>
				}
				dropdown={dropdown}
				filtering={false}
				setDropdown={setDropdown}
				FilterComponent={
					<>
						<Filtered
							dropdown={dropdown}
							setDropdown={setDropdown}
							searchInput={true}
							search={searchBank}
							setSearch={setSearchBank}
						/>
					</>
				}
			/>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<VirtualWalletModal
					fetchVirtualWallets={fetchVirtualWallets}
					closeModal={closeModal}
				/>
			</Modal>
			{getVirtualWalletsApi.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					value={value}
					data={tableRow ? tableRow : []}
					columns={ColumnVirtualWallets ? ColumnVirtualWallets : []}
					emptyPlaceHolder='You currently do not have any virtual wallets'
					total={getVirtualWalletsApi?.data?.total_items}
				/>
			)}
		</Fragment>
	);
};

export default PaymentLinks;
