import { combineReducers } from 'redux';
import toast from './toast/index';
import modalReducer from './modal/index';
import loader from './loader/index';
import navbarReducer from './navbar/index';
import newModalReducer from './newModal/index';
import subsidiaryInfoReducer from './subsidiaryInfo';
import onboardingStateReducer from './onboardingState';
import onboardLevelInfoReducer from './OnboardLevel';
import getStartedStateReducer from './getStarted';
import getIdentifierStateReducer from './identifier'
import meReducer from './me';
import appSettingsReducer from './appSettings';




const rootReducer = combineReducers({
	// customizer,
	// auth,
	// navbar,
	toast,
	modalReducer,
	loader,
	navbarReducer,
	newModalReducer,
	subsidiaryInfoReducer,
	onboardingStateReducer,
	onboardLevelInfoReducer,
	getStartedStateReducer,
	getIdentifierStateReducer,
	meReducer,
	appSettingsReducer
});

export default rootReducer;
export type AppState = ReturnType<typeof rootReducer>;
