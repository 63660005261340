import React, { useEffect } from 'react';
import styles from './beneficiary.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';

function AddBank({ closeModal, fn }: any) {
	const paymentTypes: any[] = [];
	const [selectedPaymentType, setSelectedPaymentType] = React.useState<any>();
	const [selectedType, setSelectedType] = React.useState<any>();
	const [banks, setBanks] = React.useState<any>([]);
	const [country, setCountry] = React.useState<any>([]);
	const [selectedBank, setSelectedBank] = React.useState<any>();
	const [resolve, setResolved] = React.useState<boolean>(true);

	const dispatch = useDispatch();

	interface initTypes {
		accountNumber: string;
		accountName: string;
	}

	const initProps: initTypes = {
		accountNumber: '',
		accountName: '',
	};

	const [inputs, setInputs] = React.useState(initProps);

	const fetchBanks = async () => {
		try {
			const data: any = await client.get('/subsidiary/dashboard/banks');
			setBanks(data?.data?.banks);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};
	const fetchCountry = async () => {
		try {
			const data: any = await client.get(
				'/subsidiary/dashboard/service/countries'
			);
			setCountry(data?.data?.data);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const Type = [
		{
			id: 1,
			name: 'Savings',
		},
		{
			id: 2,
			name: 'Current',
		},
	];

	let selectedBanksOptions = banks?.map(function (bank: any) {
		return {
			value: bank.id,
			label: bank.name,
			code: bank.bankCode,
		};
	});
	let selectedPaymentTypeOptions = country?.map(function (cont: any) {
		return {
			value: cont.id,
			label: cont.name,
		};
	});
	let selectedTypeOptions = Type?.map(function (ty: any) {
		return {
			value: ty.name,
			label: ty.name,
		};
	});
	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	useEffect(() => {
		fetchBanks();
		fetchCountry();
	}, []);

	const payload = {
		...inputs,
		accountType: selectedType?.value,
		bankId: selectedBank?.value,
	};

	const resolveAccount = async () => {
		if (
			!!inputs?.accountNumber &&
			inputs?.accountNumber?.toString()?.length >= 10
		) {
			dispatch(openLoader());
			try {
				const data: any = await client.post(
					'/subsidiary/payout/account/resolve',
					{
						accountNumber: inputs?.accountNumber,
						bankCode: selectedBank?.code,
					}
				);
				setInputs((prevState: initTypes) => {
					return {
						...prevState,
						accountName: data?.data?.data?.accountName,
					};
				});

				setResolved(false);

				const message = data?.data?.message;
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastSuccessStyles,
						toastContent: message,
					})
				);
			} catch (error: any) {
				const { message } = error?.response?.data;
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastErrorStyles,
						toastContent: message,
					})
				);
			}
		}
	};

	useEffect(() => {
		if (inputs?.accountNumber && selectedBank?.code !== '') resolveAccount();
	}, [inputs?.accountNumber, selectedBank?.code]);

	const clickHandler = () => {
		dispatch(openLoader());
		client
			.post('/subsidiary/dashboard/onboarding/bank/details', payload)
			.then((data: any) => {
				dispatch(closeLoader());
				fn();
				closeModal();
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
			})
			.catch((error: any) => {
				const message = error?.response?.data?.message;
				dispatch(closeLoader());

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Add Bank Account</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<div className={styles.modalselect}>
					<label className={styles.inputLabel}>Account Type</label>
					<Select
						defaultValue={selectedType}
						onChange={setSelectedType}
						options={selectedTypeOptions}
						// styles={customStyles}
						className={styles.select}
						styles={{
							// Fixes the overlapping problem of the component
							menu: (provided) => ({
								...provided,
								zIndex: 9999,
								width: '100%',
							}),
						}}
					/>
				</div>



				<div className={styles.modalselect}>
					<label className={styles.inputLabel}>Bank</label>
					<Select
						defaultValue={selectedBank}
						onChange={setSelectedBank}
						options={selectedBanksOptions}
						// styles={customStyles}
						className={styles.select}
						styles={{
							// Fixes the overlapping problem of the component
							menu: (provided) => ({
								...provided,
								zIndex: 9999,
								width: '100%',
							}),
						}}
					/>
				</div>

				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='accountNumber'
						inputLabel='Account Number'
						inputValue={inputs.accountNumber}
						style={{ width: '100%' }}
					/>
				</div>
				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='accountName'
						inputLabel='Account Name'
						inputValue={inputs.accountName}
						style={{ width: '100%' }}
						disabled
					/>
				</div>

				<Divider style={{ margin: 0, padding: 0 }} />

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={closeModal}>
						Cancel
					</button>

					{!resolve ? (
						<button onClick={clickHandler} className={styles.fund}>
							Add Beneficiary
						</button>
					) : (
						<button className={styles.fundDisable}>Add Beneficiary</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default AddBank;
