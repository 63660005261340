import { Divider, Form, Input, Select } from 'antd';
import styles from './BusinessRegistration.module.scss';
import client, { formClient } from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import React, { useEffect } from 'react';
import moment from 'moment';
const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};

function EditStakeholder({ handleEditCancel, getStake, editStake }: any) {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const INITIALVALUES3 = {
		bvn: editStake?.bvn,
		first_name: editStake?.firstName,
		last_name: editStake?.lastName,
		gender: editStake?.gender,
		date_of_birth: moment(editStake?.dateOfBirth).format('YYYY-MMMM-DD'),
		mobile_number: editStake?.mobileNumber,
	};

	useEffect(() => {
		form.setFieldsValue({
			bvn: editStake?.bvn,
			first_name: editStake?.firstName,
			last_name: editStake?.lastName,
			gender: editStake?.gender,
			date_of_birth: moment(editStake?.dateOfBirth).format('YYYY-MM-DD'),
			mobile_number: editStake?.mobileNumber,
		});
	}, [editStake]);

	const resetCancel = () => {
		handleEditCancel();
		form.resetFields();
	};

	const editedStakeHandler = (values: any) => {
		dispatch(openLoader());
		client
			.patch(`/subsidiary/dashboard/onboarding/stakeholder/update`, {
				...values,
				id: editStake?.id,
			})
			.then((res: any) => {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: res.data.message,
						toastStyles: ToastSuccessStyles,
					})
				);
				getStake();
				handleEditCancel();
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};
	return (
		<Form
			form={form}
			initialValues={INITIALVALUES3}
			layout='vertical'
			name='nest-messages-edit'
			onFinish={editedStakeHandler}
			validateMessages={validateMessages}>
			<div className={styles.flexInput}>
				<Form.Item
					style={{ flex: 1, marginRight: '16px' }}
					label='First name'
					name='first_name'
					rules={[{ required: true }]}>
					<Input style={{ borderRadius: '7px' }} className={styles.input} />
				</Form.Item>

				<Form.Item
					style={{ flex: 1 }}
					label='Last name'
					name='last_name'
					rules={[{ required: true }]}>
					<Input style={{ borderRadius: '7px' }} className={styles.input} />
				</Form.Item>
			</div>
			<div className={styles.flexInput}>
				<Form.Item
					style={{ flex: 1, marginRight: '16px' }}
					name='gender'
					label='Gender'
					className='selectOptions'
					rules={[{ required: true }]}>
					<Select allowClear>
						<Option value='yes'>Male</Option>
						<Option value='no'>Female</Option>
					</Select>
				</Form.Item>
				<Form.Item
					style={{ flex: 1 }}
					label='Date of Birth'
					name='date_of_birth'
					rules={[{ required: true }]}>
					<Input
						style={{ borderRadius: '7px' }}
						className={styles.input}
						placeholder='YYYY-MM-DD'
						type='date'
					/>
				</Form.Item>
			</div>
			<div className={styles.flexInput}>
				<Form.Item
					style={{ flex: 1, marginRight: '16px' }}
					label='Phone number'
					name='mobile_number'
					rules={[{ required: true }]}>
					<Input style={{ borderRadius: '7px' }} className={styles.input} />
				</Form.Item>

				<Form.Item
					style={{ flex: 1 }}
					name='bvn'
					label='BVN'
					rules={[{ required: true }]}>
					<Input style={{ borderRadius: '7px' }} className={styles.input} />
				</Form.Item>
			</div>
			<Divider />
			<div className={styles.buttonflex}>
				<div onClick={resetCancel} className={styles.cancel}>
					Back
				</div>
				<Form.Item shouldUpdate>
					{() => (
						<button type='submit' className={styles.submitHandler}>
							Save
						</button>
					)}
				</Form.Item>
			</div>
		</Form>
	);
}

export default EditStakeholder;
