import React, { useState, Fragment } from 'react';
import styles from './ChargeBackMenu.module.scss';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import RefundIcon from '../../../assets/images/Icons/refund-icon.svg';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import Menus from '../Menus/Menus';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import moment from 'moment';
import { numberWithCommas } from '../../../utils/formatNumber';
import StatusView from '../../StatusView/StatusView';
import TreatChargeback from '../../ModalContent/TreatChargeback/TreatChargeback';
import { useHistory } from 'react-router-dom';

type MenuProps = {
	data: any;
	cb: any;
};
const ChargeBackMenu = ({ data, cb }: MenuProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [menuOption, setMenuOption] = useState(false);
	const [verifyPayoutModalIsOpen, setVerifyPayoutModalIsOpen] = React.useState(false);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}

	function closeVerifyPayoutModalIsOpen() {
		setVerifyPayoutModalIsOpen(false);
		cb && cb();
	}
	function openModal() {
		setIsOpen(true);
	}
	const handleClickAway = () => {
		setMenuOption(false);
	};
	const handleMenu = () => {
		setMenuOption(!menuOption);
	};

	const treatChargeback = async () => {
		// dispatch(openLoader());
		try {
			// const batchId = data?.batchId;
			// const res: any = await client.get(`/subsidiary/payout/resend/otp/${batchId}`);
			// const message = res?.data?.message;
			// dispatch(
			// 	openToastAndSetContent({
			// 		toastStyles: ToastSuccessStyles,
			// 		toastContent: message,
			// 	})
			// );
			setVerifyPayoutModalIsOpen(true);
			// openModal();
			// closeModal && closeModal();
		} catch (error: any) {
			// const { message } = error?.response?.data;
			// dispatch(
			// 	openToastAndSetContent({
			// 		toastStyles: ToastErrorStyles,
			// 		toastContent: message,
			// 	})
			// );
		}
		finally {
			// dispatch(closeLoader());
		}
	};
	return (
		<Fragment>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className={styles.menuBody}>
					<div className={styles.menuIcon} onClick={handleMenu}>
						<MenuIcon />
					</div>

					{menuOption && (
						<div className={styles.menuContent} onClick={handleClickAway}>
							<Menus
								icon={ViewIcon}
								optionName={'View details'}
								onclick={() => history.push(`/dashboard/chargeback/${data.id}/details`)}
							/>
						</div>
					)}
				</div>
			</ClickAwayListener>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<div className={styles.modalBody}>
					<div className={styles.modalHeader}>
						<div className={styles.background}>
							<div className={styles.linkDetails}>Chargeback Details</div>
							<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
						</div>
					</div>
					{data && (
						<div className={styles.modalContent}>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Date</div>
								<div className={styles.content}>
									{moment(data?.dateCreated).format('MMMM Do YYYY, h:mm a')}
								</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Amount</div>
								<div className={styles.content}>
									{`${data?.currency} ${numberWithCommas(data?.amount)}`}
								</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Status</div>
								<div className={styles.content}>
									<StatusView
										status={data?.status}
										green={'Won'}
										orange='Pending | New'
										red={'Lost'}
									/>
								</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Customer Email</div>
								<div className={styles.content}>{data?.customerEmail}</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Merchant Name</div>
								<div className={styles.content}> {data?.merchantName}</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Order Reference</div>
								<div className={styles.content}>{data?.transactionReference}</div>
							</div>
							<div className={styles.modalDetails}>
								<div className={styles.link}>Payment Reference</div>
								<div className={styles.content}>{data?.rrn}</div>
							</div>
							{data?.status?.toLowerCase() == "new" || data?.status?.toLowerCase() == "pending" && <div className={styles.verifyPayoutBtn}>
								<button onClick={treatChargeback}>Treat Chargeback</button>
							</div>}
						</div>
					)}
				</div>
			</Modal>
			<Modal
				isOpen={verifyPayoutModalIsOpen}
				onRequestClose={closeVerifyPayoutModalIsOpen}
				contentLabel="Example Modal"
				style={customStyles}>
				<TreatChargeback data={data} closeModal={closeVerifyPayoutModalIsOpen} />
			</Modal>
		</Fragment>
	);
};

export default ChargeBackMenu;
