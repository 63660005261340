import React, { useEffect } from 'react';
import styles from './Bank.module.scss';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import Select from 'react-select';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';

const Bank = ({ closeModal, fn }: any) => {
	const dispatch = useDispatch();
	const [banks, setBanks] = React.useState<any>([]);
	const [selectedBank, setSelectedBank] = React.useState<any>();
	const [selectedType, setSelectedType] = React.useState<any>({
		value: 'Bank',
		label: 'Bank',
	});
	const [resolveData, setResolvedData] = React.useState<any>();

	const [currency, setCurrency] = React.useState<any>([]);
	const [selectedCurrency, setSelectedCurrency] = React.useState<any>();

	const Type = [
		{
			id: 1,
			name: 'Bank',
		},
		{
			id: 2,
			name: 'Merchant',
		},
		{
			id: 3,
			name: 'Mobile',
		},
	];

	let selectedTypeOptions = Type?.map(function (ty: any) {
		return {
			value: ty.name,
			label: ty.name,
		};
	});

	const fetchBanks = async () => {
		try {
			const data: any = await client.get('/subsidiary/dashboard/banks');						
			setBanks(data?.data?.banks);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	let selectedBanksOptions = banks?.map(function (bank: any) {		
		return {
			value: bank.id,
			label: bank.name,
			code: bank.bankCode,
		};
	});

	const fetchCurrencies = async () => {
		try {
			const data: any = await client.get(
				'/subsidiary/dashboard/service/currencies'
			);
			setCurrency(data?.data?.data);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	let currencyOptions = currency?.map(function (currency: any) {
		return {
			value: currency.id,
			label: currency.name,
			shortName: currency.shortName,
		};
	});
	interface initTypes {
		accountNumber: number | undefined;
		description: string;
		amount: number | undefined;
	}

	const initProps: initTypes = {
		accountNumber: undefined,
		description: '',
		amount: undefined,
	};
	const [inputs, setInputs] = React.useState(initProps);

	const disableFunction = () => {
		if (
			inputs.accountNumber &&
			!!selectedType &&
			!!resolveData?.data?.accountName
		) {
			return true;
		}
	};

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	useEffect(() => {
		fetchBanks();
		fetchCurrencies();
	}, []);
	const payloadBank = {
		bankId: selectedBank?.value,
		countryId: 1,
		type: selectedType?.value,
		AccountName: resolveData?.data?.accountName,
		accountNumber: inputs.accountNumber,
	};
	const payloadMobile = {
		countryId: 1,
		type: selectedType?.value,
		AccountName: resolveData?.data?.accountName,
		accountNumber: inputs.accountNumber,
	};
	const payloadMerchant = {
		countryId: 1,
		type: selectedType?.value,
		AccountName: resolveData?.data?.accountName,
		accountNumber: inputs.accountNumber,
	};

	const benfy = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.post(
				'/subsidiary/beneficiary/create',
				selectedType?.value === 'Bank'
					? payloadBank
					: selectedType?.value === 'Mobile'
					? payloadMobile
					: selectedType?.value === 'Merchant'
					? payloadMerchant
					: []
			);
			const message = data?.data?.message;
			dispatch(closeLoader());
			closeModal();
			fn();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const resolveAccount = async () => {
		if (!!inputs?.accountNumber) {
			dispatch(openLoader());
			try {
				
				const data: any = await client.post('/subsidiary/beneficiary/resolve', {
					type: selectedType?.value,
					accountNumber: inputs?.accountNumber,
					bankCode: selectedBank?.code,
				});
				setResolvedData(data?.data);
				console.log('just to:', data.data);
				const message = data?.data?.message;
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastSuccessStyles,
						toastContent: message,
					})
				);
			} catch (error: any) {
				const { message } = error?.response?.data;
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastErrorStyles,
						toastContent: message,
					})
				);
			}
		}
	};

	useEffect(() => {
		if (inputs?.accountNumber && selectedBank?.code !== '') resolveAccount();
	}, [inputs?.accountNumber, selectedBank?.code]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		benfy();
	};
	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>Add New Beneficiary</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			<div className={styles.businessForm}>
				<form onSubmit={handleSubmit} className={styles.form}>
					<div className={styles.modalselect}>
						<label className={styles.inputLabel}>Type</label>
						<Select
							defaultValue={selectedType}
							onChange={setSelectedType}
							options={selectedTypeOptions}
							// styles={customStyles}
							className={styles.select}
							styles={{
								// Fixes the overlapping problem of the component
								menu: (provided) => ({
									...provided,
									zIndex: 9999,
									width: '100%',
								}),
							}}
						/>
					</div>

					{selectedType?.value === 'Bank' && (
						<div className={styles.modalselect}>
							<label className={styles.inputLabel}>Bank</label>
							<Select
								defaultValue={selectedBank}
								onChange={setSelectedBank}
								options={selectedBanksOptions}
								className={styles.select}
								styles={{
									// Fixes the overlapping problem of the component
									menu: (provided) => ({
										...provided,
										zIndex: 9999,
										width: '100%',
									}),
								}}
							/>
						</div>
					)}
					{selectedType?.value === 'Mobile' && (
						<OutlineTextInput
							handleChange={updateProps}
							inputName='accountNumber'
							inputLabel='Mobile Number'
							inputValue={inputs.accountNumber}
						/>
					)}
					{selectedType?.value === 'Merchant' && (
						<OutlineTextInput
							handleChange={updateProps}
							inputName='accountNumber'
							inputLabel='Merchant ID'
							inputValue={inputs.accountNumber}
						/>
					)}
					{selectedType?.value === 'Bank' && (
						<OutlineTextInput
							handleChange={updateProps}
							inputName='accountNumber'
							inputLabel='Account number'
							inputValue={inputs.accountNumber}
						/>
					)}

					{resolveData && (
						<p>
							Account Name:{' '}
							<span style={{ color: 'green' }}>
								{resolveData?.data?.accountName}
							</span>{' '}
						</p>
					)}

					{/* <div className={styles.modalselect}>
						<label className={styles.inputLabel}>Currency </label>
						<Select
							defaultValue={selectedCurrency}
							onChange={setSelectedCurrency}
							options={currencyOptions}
							className={styles.select}
							styles={{
								// Fixes the overlapping problem of the component
								menu: (provided) => ({
									...provided,
									zIndex: 9999,
									width: '100%',
								}),
							}}
						/>
					</div> */}
				</form>
			</div>
			<div className={styles.modalFooter}>
				<div className={styles.cancel} onClick={closeModal}>
					Cancel
				</div>
				{disableFunction() ? (
					<div className={styles.deactivate} onClick={handleSubmit}>
						Create
					</div>
				) : (
					<div className={styles.notClickable}>Create</div>
				)}
			</div>
		</div>
	);
};

export default Bank;
