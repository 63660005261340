import { useState, Fragment, useEffect } from "react";
import styles from "./Transactions.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnTransactions, TransactionsData, } from "../../types/TablesTypes/Transactions";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import { openLoader, closeLoader, } from "../../redux/actions/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import { ToastErrorStyles, } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";

const Transactions = ({ eTransactions, cb }: any) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();
  const [transactions, setTransactions] = useState<any>(eTransactions);
  const { currency } = useSelector((state) => state.appSettingsReducer);

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [transactionType, settransactionType] = useState("")
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    settransactionType("")
    setStatus("");
    setDropdown(false);
    setBearer(true);
  };
  const filteredArray = [
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { value: "", name: "All" },
        { name: "Initiated" },
        { name: "Awaiting-Confirmation" },
        { name: "Pending" },
        { name: "Successful" },
        { name: "Reversed" },
        { name: "Failed" },
        { name: "Processing" },
      ],
    },
    {
      id: 2,
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
    },
    {
      id: 3,
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
    },
    {
      id: 4,
      name: "Transaction Type",
      value: transactionType,
      setValue: settransactionType,
      selective: [
        { value: "", name: "All" },
        { name: "Card" },
        { name: "Transfer" },
      ],
    },
  ];
  const getTransactionsApi = useApi(transactionsApi.getTransactions);

  const fetchTransactions = async (isExport = false) => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `/subsidiary/dashboard/fetch/orders?currency=${currency}&status=${status}&paymentreference=${paymentreference}&orderreference=${orderreference}&transactionType=${transactionType}&fromdate=${fromDate}&todate=${toDate}&isDownload=${isExport}`
      );
      setTransactions(data?.data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  //new addition to fetch all transactions with limit of 1000, regardless of pagination
  const fetchAllTransactions = async (isExport = false) => {
    const limit = 1000;
    try {
        const { data }: any = await client.get(
            `/subsidiary/dashboard/fetch/orders?currency=${currency}&limit=${limit}&status=${status}&paymentreference=${paymentreference}&orderreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&isDownload=${isExport}`
        );
        // Check if the response contains the 'data' property and if it has an 'items' array
        if (data?.data?.items && Array.isArray(data.data.items)) { //chec if data exists, the response
           // Loop through each item in the 'items' array
            data.data.items.forEach((obj: any) => {
              // Remove the 'processorProviderBank' property from each transaction object
                delete obj.processorProviderBank; 
            });
           // Update the state with the modified 'data.data.items' (which now lacks 'processorProviderBank')
            setTransactions(data?.data);
        }
        dispatch(closeLoader());
        setBearer(false);
    } catch (error: any) {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
        );
        return [];
    }
}

  useEffect(() => {
    if (!eTransactions) fetchTransactions();
  }, [currency, bearer]);

  const dataTransaction = () => {
    const tempArr: TransactionsData[] = [];
    (eTransactions ?? transactions)?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        const amount = Number(transaction?.amountFormatted ?? transaction?.transactionAmount);
        const fee = Number(transaction?.fee ?? transaction?.fee);
        const settlementAmount = amount - fee;
        tempArr.push({
          date: moment(transaction?.dateCreated).format("DD-MMM-YYYY hh:mm:ss a"),
          // email: transaction?.customerEmail,
          email: transaction?.customerEmail ?? transaction?.order?.customer?.emailAddress,
          amount: `${transaction?.currency?.shortName ?? transaction?.currency} ${numberWithCommas(amount)}`,
          settlementAmount: `${transaction?.currency?.shortName ?? transaction?.currency} ${numberWithCommas(settlementAmount)}`,
          payment: transaction?.paymentTypeName ?? transaction?.order?.paymentTypeName ?? transaction?.order?.paymentType ?? 'NIL',
          reference: transaction?.orderReference ?? transaction?.transactionReference,
          status: (
            <StatusView
              status={transaction?.orderStatus ?? transaction?.status?.name}
              green="Successful"
              red="Awaiting-Confirmation"
              orange="Initiated"
            />
          ),
          action: (
            <TransactionsMenu
              currency={transaction?.currency?.shortName ?? transaction?.currency}
              transactionId={transaction?.id}
              fetchTransactions={fetchTransactions}
              amount={Number(transaction?.amountFormatted)}
              customerId={transaction?.customerId}
              status={transaction?.orderStatus}
              reference={transaction?.paymentReference}
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [transactions?.items, eTransactions?.items]);

  return (
    <Fragment>
      <TableHeader
        pageName="Transactions"
        dataLength={(eTransactions ?? transactions)?.page?.total}
        data={(eTransactions ?? transactions)?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        // fetchExportDataHandler={async () => eTransactions ? (cb && cb(true)) : await fetchTransactions(true)}
        fetchExportDataHandler={async () => eTransactions ? (cb && cb(true)) : await fetchAllTransactions(true)}

        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />

      {getTransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnTransactions ? ColumnTransactions : []}
          emptyPlaceHolder="You currently do not have any transactions"
          total={(eTransactions ?? transactions)?.page?.total}
        />
      )}
    </Fragment>
  );
};

export default Transactions;
