import React from 'react';

export interface SubsidiariesData {
	businessName: string;
	supportEmail: string;
	country: string;
	status: React.ReactNode;
	businessType: string;
}

export const ColumnSubsidiaries = [
	{
		Header: 'Name',
		accessor: 'businessName',
	},
	{
		Header: 'Email',
		accessor: 'supportEmail',
	},
	{
		Header: 'Country',
		accessor: 'country',
	},
	{
		Header: 'Verification Status',
		accessor: 'status',
	},
	{
		Header: 'Business Type',
		accessor: 'businessType',
	},
];
