import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import styles from './multiplr.module.scss';
import downloady from '../../assets/images/Icons/downloady.svg';
const csv = require('csvtojson');

export default class MultipleUpload extends Component {
	state = {
		files: [],
	};

	onDrop = (acceptedFiles, rejectedFiles) => {
		this.setState({
			files: acceptedFiles,
		});

		acceptedFiles.forEach((file) => {
			const reader = new FileReader();

			reader.onload = () => {
				const fileAsBinaryString = reader.result;

				csv({
					noheader: true,
					output: 'json',
				})
					.fromString(fileAsBinaryString)
					.then((csvRows) => {
						const toJson = [];
						csvRows.forEach((aCsvRow, i) => {
							if (i !== 0) {
								const builtObject = {};

								Object.keys(aCsvRow).forEach((aKey) => {
									const valueToAddInBuiltObject = aCsvRow[aKey];
									const keyToAddInBuiltObject = csvRows[0][aKey];
									builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
								});
								toJson.push({
									...builtObject,
								});
							}
						});
						this.props.onDrop(toJson);
					});
			};

			reader.onabort = () => console.log('file reading was aborted');
			reader.onerror = () => console.log('file reading has failed');

			reader.readAsBinaryString(file);
		});
	};

	render() {
		return (
			<section className='w-full'>
				<div>
					<Dropzone
						className='w-full'
						onDrop={this.onDrop.bind(this)}
						accept={['.csv', 'text/csv']}
						multiple={false}>
						<div className={styles.modalupload}>
							<img src={downloady} alt='' />

							<label style={{ cursor: 'pointer' }}>
								<button>Choose file to upload</button>
							</label>

							<ul>
								{this?.state?.files?.map((f) => (
									<li key={f?.name}>
										{f?.name} - {f?.size} bytes
									</li>
								))}
							</ul>

							<p>Upload the refund CSV file to begin</p>
						</div>
					</Dropzone>
				</div>
			</section>
		);
	}
}
