import React, { useState, Fragment } from 'react';
import styles from './Refundsmenu.module.scss';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import RefundIcon from '../../../assets/images/Icons/refund-icon.svg';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import Menus from '../Menus/Menus';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import moment from 'moment';
import { numberWithCommas } from '../../../utils/formatNumber';
import { useHistory } from 'react-router-dom';

const RefundsMenu = ({ customerId }: { customerId: number | string }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [menuOption, setMenuOption] = useState(false);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	function openModal() {
		setIsOpen(true);
	}
	const handleClickAway = () => {
		setMenuOption(false);
	};
	const handleMenu = () => {
		setMenuOption(!menuOption);
	};

	return (
		<Fragment>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className={styles.menuBody}>
					<div className={styles.menuIcon} onClick={handleMenu}>
						<MenuIcon />
					</div>

					{menuOption && (
						<div className={styles.menuContent} onClick={handleClickAway}>
							<Menus
								icon={ViewIcon}
								optionName={'View details'}
								onclick={(e: any) => {
									history.push(
										`/dashboard/refunds/refund-details/${customerId}`
									);
								}}
							/>
						</div>
					)}
				</div>
			</ClickAwayListener>
		</Fragment>
	);
};

export default RefundsMenu;
