import { useState, useEffect } from 'react';
import styles from './Dashboard.module.scss';
import { useHistory } from 'react-router-dom';
import Card from '../../components/Card/Card';
import Chart from '../../components/Chart/Chart';
import BalanceCard from '../../components/Card/BalanceCard';
import TimerIcon from '../../assets/images/Icons/timer.svg';
import VolumeIcon from '../../assets/images/Icons/volume-icon.svg';
import SettlementIcon from '../../assets/IconsMerchant/asettlement.svg';
import BalanceIcon from '../../assets/IconsMerchant/abalance.svg';
import LedgerIcon from '../../assets/images/Icons/ledger-icon.svg';
import useApi from '../../hooks/useApi';
import transactionsSummaryApi from '../../api/transactionsSummary';
import balanceApi from '../../api/walletBalance';
import client from '../../api/client';
import { useSelector } from 'react-redux';

const Dashboard = () => {
	const [filterParam, setFilterParam] = useState<any>('year');
	const [inflowData, setInflowData] = useState<any>();

	const history = useHistory();
	// const dispatch = useDispatch();

	const getTransactionsSummaryApi = useApi(
		transactionsSummaryApi.getTransactionsSummary
	);

	const { currency } = useSelector((state) => state.appSettingsReducer);

	const WalletBalanceApi = useApi(balanceApi.getWalletBalance);

	const fetchInflow = async () => {
		const { data }: any = await client.get(
			`/subsidiary/metric/inflow/outflow?type=${filterParam}&currency=${currency}`
		);
		setInflowData(data?.data);
	};

	// useEffect(() => {
	// 	dispatch(changeNavbar('Dashboard'));
	// });

	const fetchWalletBalance = async () => {
		WalletBalanceApi.request();
	};
	useEffect(() => {
		fetchWalletBalance();
		fetchInflow();
	}, [filterParam, currency]);

	useEffect(() => {
		getTransactionsSummaryApi.request(currency);
	}, [currency]);

	const walletBalance = WalletBalanceApi?.data?.wallets[0];

	const data = getTransactionsSummaryApi?.data?.data;

	const response = {
		count: data?.count ?? 0,
		volume: data?.volume ?? 0,
		settlements: data?.settlements,
		settlementChange: data?.settlementChange ?? 0,
		volumeChange: data?.volumeChange,
		countChange: data?.countChange ?? 0,
		availableBalance: walletBalance?.availableBalance ?? 0,
		balanceChange: walletBalance?.availableBalanceChange ?? 0,
		ledgerBalance: walletBalance?.ledgerBalance ?? 0,
		bankName: walletBalance?.accountDetails?.bankName ?? 0,
		accountNumber: walletBalance?.accountDetails?.accountNumber,
	};

	return (
		<div>
			{getTransactionsSummaryApi.loading ? (
				<div className={styles.loading} />
			) : (
				<>
					<div className={styles.scrolwrapper}>
						<div>
							<BalanceCard
								currency={currency}
								icon={LedgerIcon}
								infoContent='This is how much you have made and will be settled in your preferred account on the due date.'
								percentage={response.balanceChange}
								amount={response.ledgerBalance}
								transactionType='Collection Balance'
								onClick={() => {
									history.push('dashboard/account/balances');
								}}
							/>
						</div>
						<div>
							<BalanceCard
								currency={currency}
								icon={BalanceIcon}
								percentage={walletBalance && response.balanceChange}
								amount={response.availableBalance}
								infoContent='Your Payout Balance is money for transfers.'
								transactionType='Payout Balance'
								onClick={() => {
									history.push('dashboard/account/balances');
								}}
							/>
						</div>
						<div>
							<Card
								currency={currency}
								icon={TimerIcon}
								percentage={response.countChange}
								amount={response.count}
								transactionType='Transaction count'
							/>
						</div>
						<div>
							<Card
								currency={currency}
								icon={VolumeIcon}
								percentage={response.volumeChange}
								amount={response.volume}
								transactionType='Transaction volume'
							/>
						</div>
						<div>
							<Card
								currency={currency}
								icon={SettlementIcon}
								percentage={response.settlementChange}
								amount={response.settlements}
								transactionType='Transaction settlements'
							/>
						</div>
					</div>
					<div className={styles.chart}>
						<Chart
							inflowData={inflowData}
							filterParam={filterParam}
							setFilterParam={setFilterParam}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default Dashboard;
