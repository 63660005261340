import { Form, FormInstance, Input, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./GetStarted.module.scss";
import { ReactComponent as IndividualIcon } from "../../assets/IconsMerchant/individualpick.svg";
import { ReactComponent as BusinessIcon } from "../../assets/IconsMerchant/registerpick.svg";
import { useDispatch, useSelector } from "react-redux";
import { onboardingState as onboardingfunc } from "../../redux/actions/onboardingState/onbodardingState";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../constants/toastStyles";
import client, { formClient } from "../../api/client";
import utils from "../../api/utilities";
import { saveOnboardStateInfo } from "../../redux/actions/onboardlevel/onboardLevelAction";
import useApi from "../../hooks/useApi";

const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

interface SubSectorType {
  subsectorId: number;
  subSectorName: string;
}

interface BusinessSizeType {
  name: string;
  description: string;
  id: number;
  dateCreated: string;
  dateUpdated: null | string;
  dateDeleted: null | string;
}

interface sectorType {
  subSectors: SubSectorType[];
  sectorName: string;
  description: string;
  sectorId: number;
  dateCreated: string;
  dateUpdated: null | string;
  dateDeleted: null | string;
}

interface countryType {
  name: string;
  countryCode: string;
  currency: string;
  currencyId: number;
  shortName: string;
  isOfac: null | string;
  isEuro: null | string;
  allowed: boolean;
  id: number;
  dateCreated: string;
  dateDeleted: string;
}

function GetStarted() {

  // code handeling dynamic dropdown for subsectors
  const [selectedSubSectors, setSelectedSubSectors] = useState<SubSectorType[]>([]);

  const handleSectorChange = (e: { target: { value: any; }; }) => {
    const selectedSectorName = e.target.value;

    const selectedSector = sectors.find(
      (sector) => sector.sectorName === selectedSectorName
    );
    if (selectedSector) {
      setSelectedSubSectors(selectedSector.subSectors || []);
    }
  };

  // code handeling dynamic dropdown for subsectors end

  const bSectorApi = useApi(utils.getBusinessSectors);
  const bSizeApi = useApi(utils.getBusinessSize);
  const countryApi = useApi(utils.getCountries);

  const sectors: sectorType[] = (bSectorApi?.data?.data ?? []);
  const businessSizes: BusinessSizeType[] = (bSizeApi?.data?.data ?? []);
  const countries: countryType[] = (countryApi?.data?.data ?? []);
  // const [categories, setCategories] = useState<categoryType[]>(bCategoryApi?.data?.data ?? []);
  // const [countries, setCountries] = useState<countryType[]>([]);
  // const [businessSizes, setBusinessSizes] = useState<countryType[]>([]);

  const { onboardingState } = useSelector(
    (state) => state.onboardingStateReducer
  );

  const history = useHistory();
  const res = useSelector(
    (state) => state?.onboardLevelInfoReducer?.onboardLevelInfo
  );

  const subsidiaries = useSelector(
    (state) => state?.meReducer?.meInfo?.subsidiaryDetails?.subsidiaries
  );
  const subsidiary = subsidiaries?.length > 0 ? subsidiaries[0] : {};
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const getCategory = () => {
  //   dispatch(openLoader())
  //   client
  //     .get(`/subsidiary/dashboard/business/categories`)
  //     .then((data: any) => {
  //       console.log("datap:", data?.data);
  //       setCategories(data?.data?.data);
  //     })
  //     .catch((error: any) => {
  //       const { message } = error?.response?.data;
  //       dispatch(
  //         openToastAndSetContent({
  //           toastContent: message,
  //           toastStyles: ToastErrorStyles,
  //         })
  //       );
  //     })
  //     .finally(() => {
  //       dispatch(closeLoader())
  //     })
  //     ;
  // };

  // const getCountry = () => {
  //   client
  //     .get(`/subsidiary/dashboard/service/countries`)
  //     .then((data: any) => {
  //       setCountries(data?.data?.data);
  //     })
  //     .catch((error: any) => {
  //       const { message } = error?.response?.data;
  //       dispatch(
  //         openToastAndSetContent({
  //           toastContent: message,
  //           toastStyles: ToastErrorStyles,
  //         })
  //       );
  //     });
  // };

  const INITIALVALUES = {
    businessName: subsidiary?.name ?? "",
    country: {
      value: `NG`,
      label: `--Choose your option--`,
    },
    businessType: "Company",
    businessSize: {
      value: ``,
      label: `--Choose your option--`,
    },
    isDeveloper: {
      value: ``,
      label: `--Choose your option--`,
    },
    businessSectorId: '',
    referal_code: "",
  };

  const size = [
    "0-10 employees",
    "10-50 employees",
    "50-100 employees",
    "100++ employees",
  ];

  const identifierChange = (type: string) => {
    if (type === "Individual") {
      dispatch(onboardingfunc(type));
      form.setFieldValue("businessType", type);
    } else if (type === "Company") {
      dispatch(onboardingfunc(type));
      form.setFieldValue("businessType", type);
    } else {
      return;
    }
  };

  const trigger = () => {
    if (
      (res?.data?.personalInformation === 100 ||
        res?.data?.businessCompliance === 100) &&
      res?.data?.businessInformation === 100 &&
      res?.data?.bankInformation === 100 &&
      res?.data?.termsAndCondition === 100
    ) {
      dispatch(saveOnboardStateInfo(false));
    } else {
      dispatch(saveOnboardStateInfo(true));
    }
  };

  const getBusinessSectorId = (sectorName: string) => {
    return sectors.find(x => x.sectorName == sectorName)?.sectorId;
  };
  const getSubSectorId = (sectorName: string, subSectorName: string) => {
    const sector = sectors.find(x => x.sectorName === sectorName);
    return sector?.subSectors.find(y => y.subSectorName === subSectorName)?.subsectorId;
  };
  
  
  const setFormValue = (form: FormInstance, value: string) => {
    form.setFieldValue("businessSector", value);
    form.setFields([
      {
        name: "businessSector",
        errors: [],
      },
    ]);
  };

  useEffect(() => {
    form.setFieldValue("businessType", onboardingState);
    bSectorApi.request();
    bSizeApi.request();
    countryApi.request();
    console.log('businessSizes:',businessSizes)
  }, []);

  // useEffect(() => {
  //   console.log(bCategoryApi.data.data)
  //   // bCategoryApi.data.data && setCategories(bCategoryApi.data.data)
  //   // countryApi.data.data && setCountries(countryApi.data.data)
  //   // bSizeApi.data.data && setBusinessSizes(bSizeApi.data.data)
  // }, [bCategoryApi.data, bSizeApi.data, countryApi.data])

  useEffect(() => {
    trigger();
  }, [dispatch]);

  const updatedHandler = (values: any) => {

    dispatch(openLoader());
    values.country = values.country.value ?? values.country
    // values.businessCategoryId = getBusinessSectorId(values.businessSector.value ?? values.businessSector) this gives the sector id 
    values.businessCategoryId = getSubSectorId( values.businessSector.value ?? values.businessSector, values.businessSubSector ); //this gives subsector id    
    client
      .post(`/subsidiary/dashboard/business/get-started`, values)
      .then((res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        history.push("/dashboard/get-Started/onboarding");
        window.location.reload();
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.wrapperh2}>Tell us about your business</h2>
      <p className={styles.wrapperp}>
        A few more things to help us set up your dashboard
      </p>
      <div className={styles.wrapperbox}>
        <Form
          form={form}
          initialValues={INITIALVALUES}
          layout="vertical"
          name="nest-messages"
          onFinish={updatedHandler}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="businessName"
            label="Business/Company name"
            rules={[{ required: true }]}
          >
            <Input
              className={styles.input}
              placeholder="Business/Company name"
              style={{ borderRadius: "7px" }}
              value={subsidiaries?.name}
            />
          </Form.Item>

          <Form.Item
            className="selectOptions"
            label="Where is your business based?"
            style={{ flex: 1 }}
            name="country"
            rules={[{ required: true }]}
          >
            <select value={"NG"} className={styles.formSelect} placeholder="Where is your business based?">
              {countries.length &&
                countries?.map(({ name, shortName }, i) => (
                  <option
                    key={i + name + Math.random()}
                    value={shortName}
                  // disabled={name !== "Nigeria"}
                  >
                    {name}
                  </option>
                ))}
            </select>
          </Form.Item>
          <Form.Item name="businessType" rules={[{ required: false }]}>
            <h4 className={styles.businessNameh4}>
              What is your business type?
            </h4>
            <div
              onClick={() => identifierChange("Individual")}
              style={{
                color: onboardingState === "Individual" ? "#8E173E" : "",
                borderColor: onboardingState === "Individual" ? "#8E173E" : "",
              }}
              className={styles.businessNameBox}
            >
              <div className={styles.boxleft}>
                <IndividualIcon />
              </div>
              <div className={styles.boxright}>
                <h3
                  style={{
                    color: onboardingState === "Individual" ? "#8E173E" : "",
                  }}
                >
                  Starter/Individual
                </h3>
                <p>An unregistered business e.g freelancers and sole traders</p>
              </div>
            </div>
            <div
              onClick={() => identifierChange("Company")}
              style={{
                color: onboardingState === "Company" ? "#8E173E" : "",
                borderColor: onboardingState === "Company" ? "#8E173E" : "",
              }}
              className={styles.businessNameBox}
            >
              <div className={styles.boxleft}>
                <BusinessIcon />
              </div>
              <div className={styles.boxright}>
                <h3
                  style={{
                    color: onboardingState === "Company" ? "#8E173E" : "",
                  }}
                >
                  Registered
                </h3>
                <p>A registered business with a corporate bank account</p>
              </div>
            </div>
          </Form.Item>
          <div className={styles.flexInput}>
            <Form.Item
              className="selectOptions"
              style={{ flex: 1, marginRight: "16px" }}
              label="Business size"
              name="businessSize"
              rules={[{ required: true }]}
            >
            <Select placeholder="Business size" allowClear>
              {businessSizes.map((item, i) => (
                <Option key={i} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>

            </Form.Item>

            <Form.Item
              style={{ flex: 1 }}
              name="isDeveloper"
              label="Are you a software developer?"
              className="selectOptions"
              rules={[{ required: true }]}
            >
              <Select
                defaultValue={true}
                placeholder="Are you a software developer?"
                allowClear
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </div>
          {/* <div className={styles.flexInput}> */}
          
          {/* business Sectors */}
          <Form.Item
            // className="selectOptions"
            label="Business Sector"
            style={{ flex: 1, marginRight: "16px" }}
            name="businessSector"
            rules={[{ required: true }]}
          >
            <Input
              className={styles.input}
              placeholder="Business Sector"
              style={{ borderRadius: "7px" }}
              // onChange={(dd) => setFormValue(form, dd.target.value)}
              onChange={(e) => {setFormValue(form, e.target.value);handleSectorChange(e);}}
              // value={getBusinessCategoryName(subsidiary?.businessCategoryId)}
              list="business-categories"
            />
            <datalist id="business-categories" placeholder="Choose your Sector">
              {sectors.length &&
                [...sectors]?.map(({ sectorId, sectorName }: any, i) => (
                  <option key={sectorId} value={sectorName} />
                ))}
            </datalist>
          </Form.Item>
          {/* business Sub Sectors */}
          {selectedSubSectors.length > 0 && (
            <Form.Item
              label="Business Sub Sector"
              style={{ flex: 1, marginRight: '16px' }}
              name="businessSubSector"
              rules={[{ required: true }]}
            >
              {/* <Input
              className={styles.input}
                placeholder="Business Sub Sector"
                style={{ borderRadius: '7px' }}
                list="business-subcategories"
              />
              <datalist id="business-subcategories">
                {selectedSubSectors.map(({ subsectorId, subSectorName }) => (
                  <option key={subsectorId} value={subSectorName} />
                ))}
              </datalist> */}
            <Select placeholder="Select Business Sub Sector" virtual={false}>
              {selectedSubSectors.map(({ subsectorId, subSectorName }) => (
                <Option key={subsectorId} value={subSectorName}>
                  {subSectorName}
                </Option>
              ))}
            </Select>
            </Form.Item>
          )}
          {/*referral categories */}
          <Form.Item
            label="Referral code"
            style={{ flex: 1 }}
            name="referalCode"
            rules={[{ required: false }]}
          >
            <Input
              style={{ borderRadius: "7px" }}
              className={styles.input}
              placeholder="Enter referral code"
            />
          </Form.Item>
          {/* </div> */}
          <Form.Item shouldUpdate>
            {() => (
              <button
                type="submit"
                disabled={
                  (!form.isFieldsTouched(["businessName"]) && !subsidiary.name) ||
                  // !form.isFieldsTouched(["country"]) ||
                  !form.isFieldsTouched(["businessSize"]) ||
                  !form.isFieldsTouched(["isDeveloper"]) ||
                  // !form.isFieldTouched(["businessCategory"]) ||
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length > 0
                }
                className={styles.submitHandler}
              >
                Proceed to my dashboard
              </button>
            )}
          </Form.Item>
        </Form>
      </div >
    </div >
  );
}

export default GetStarted;
