import { SAVE_SUBSIDIARY_INFO, SET_ACTIVE_SUBSIDIARY } from "../constants";

export const saveSubsidiaryInfo = (subsidiaryInfo: any) => {
  return {
    type: SAVE_SUBSIDIARY_INFO,
    subsidiaryInfo,
  };
};


export const setActiveSubsidiaryAction = (activeSubsidiary: any) => {
  return {
    type: SET_ACTIVE_SUBSIDIARY,
    activeSubsidiary,
  };
};
