import React, { useState, Fragment, useEffect } from "react";
import styles from "./TransferPayouts.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnPayouts, PayoutsData } from "../../types/TablesTypes/Payouts";
import TableHeader from "../../components/TableHeader/TableHeader";
import payoutsApi from "../../api/payouts";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
// import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
// import { customStyles } from "../../constants/modalStyles";
// import Modal from "react-modal";
// import InitiatePayout from "../../components/ModalContent/InitiatePayout/InitialPayout";
// import client from "../../api/client";
// import {
//   closeLoader,
//   openLoader,
// } from "../../redux/actions/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
// import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
// import { ToastErrorStyles } from "../../constants/toastStyles";
import PayoutMenu from "../../components/MenuOption/PayoutMenu/PayoutMenu";
// import Filtered from "../../components/filterComponent/Filtered";
// import { menuContent } from "../../utils/menuContent";
import StatusView from "../../components/StatusView/StatusView";
import FilteredUsage from "../../components/filterUsableComponent/FilteredUsage";
import { closeLoader, openLoader } from "../../redux/actions/loader/loaderActions";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../constants/toastStyles";

const TransferPayouts = ({ payout, cb, subb_id }: any) => {
  const dispatch = useDispatch();

  const [tableRow, setTableRow] = useState<any[]>();
  const getPayoutsApi = useApi(payoutsApi.getPayouts);
  const [value, setValue] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  // const StatusView = ({ status, green, orange, red }: any) => {
  //   return (
  //     <div>
  //       {status === "Rejected" ? (
  //         <div className={styles.declined}>Rejected</div>
  //       ) : status === "Failed" ? (
  //         <div className={styles.declined}>Failed</div>
  //       ) : status === "Declined" ? (
  //         <div className={styles.declined}>Declined</div>
  //       ) : status === "Pending" ? (
  //         <div className={styles.processing}>Pending</div>
  //       ) : status === "Processing" ? (
  //         <div className={styles.processing}>Processing</div>
  //       ) : status === "Initiated" ? (
  //         <div className={styles.processing}>Initiated</div>
  //       ) : status === "Successful" ? (
  //         <div className={styles.completed}>Successful</div>
  //       ) : status === "Approved" ? (
  //         <div className={styles.completed}>Approved</div>
  //       ) : null}
  //     </div>
  //   );
  // };
  
  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [payoutReference, setPayoutReference] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const [SearchableColumns, setSearchableColumns] = useState("");
  const [keyword, setKeyword] = useState("");
  
  const [individualPayoutData, setIndividualPayoutData] = useState<any>(null);
  
  // Function to clear filters
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setAccountNumber("");
    setRecipientName("");
    setStatus("");
    setDropdown(false);

    // Re-fetch payout data without any filters
    cb({
      fromDate: '',
      toDate: '',
      status: '',
      keyword : '',
      SearchableColumns:'',
      page: '',
    });
  };

  // Function to apply filters and trigger API call
  const applyFilters = () => {
    
    cb({
      fromDate,
      toDate,
      status,
      keyword,
      SearchableColumns
    });
  };

  const filteredArray = [
    {
      id: 1,
      name: "Beneficiary Number",
      value: accountNumber,
      setValue: (val: React.SetStateAction<string>) => {
        setAccountNumber(val);
        setKeyword(val);  // Store value in keyword
        setSearchableColumns('AccountNumber'); // Set the search column
      },
    },
    {
      id: 2,
      name: "PayoutReference",
      value: payoutReference,
      setValue: (val: React.SetStateAction<string>) => {
        setPayoutReference(val);
        setKeyword(val);  // Store value in keyword
        setSearchableColumns('PayoutReference'); // Set the search column
      },
    },
    {
      id: 3,
      name: "Recipient Name",
      value: recipientName,
      setValue: (val: React.SetStateAction<string>) => {
        setRecipientName(val);
        setKeyword(val);  // Store value in keyword
        setSearchableColumns('RecipientName'); // Set the search column
      },
    },
    {
      id: 4,
      name: "Status",
      value: status,
      setValue: (val: React.SetStateAction<string>) => {
        setStatus(val);
        setKeyword(val);  // Store value in keyword
        setSearchableColumns('status'); // Set the search column
      },
      selective: [
        // { value: "", name: "All" },
        { name: "Initiated" },
        { name: "Awaiting-Approval" },
        { name: "Awaiting-Verification" },
        { name: "Pending" },
        { name: "Successful" },
        { name: "Failed" },
        { name: "Processing" },
      ],
    },
  ];

  const mapIndividualPayoutToItem = (individualPayout: any,id: any) => {
    return {
      payoutId: id,
      payoutDate: individualPayout.dateInitiated,
      recipientName: individualPayout.recipientName,
      bankName: individualPayout.bankName,
      accountNumber: individualPayout.accountNumber,
      amount: individualPayout.amount,
      fee: individualPayout.payoutFee,
      initiator: individualPayout.initiator,
      approver: individualPayout.approver,
      status: individualPayout.status,
      payoutReference: individualPayout.payoutReference,
      batchReference: individualPayout.batchReference,
      transferType: individualPayout.type,
      debitCurrency: individualPayout.debitCurrency,
      type: individualPayout.type,
    };
  };

  // Function to fetch individual payout data
  const getIndividualPayouts = (id: any) => {
    if (!subb_id) {
			console.error("subb_id is not available, skipping API call");
			return;
		}
    dispatch(openLoader());

    client
      .get(`/subsidiary/payout/${id}`, {
        headers: {
          'subb_id': subb_id,
        },
      })
      .then((res: any) => {
        dispatch(closeLoader());
        const payoutData = res.data.data;
        const mappedPayout = mapIndividualPayoutToItem(payoutData, id);
        setIndividualPayoutData(mappedPayout);

        setBearer(false);
      })
      .catch((error: { response: { data: { message: any } } }) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: message,
          })
        );
      });
  };

  const dataPayout = () => {
    const tempArr: PayoutsData[] = [];
    payout?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((payout: any, index: number) => {
        tempArr.push({
          dateInitiated: moment(payout?.dateInitiated).format(
            "MMMM Do YYYY, h:mm a"
          ),
          recipientName: payout?.recipient,
          accountNumber: payout?.beneficiaryNumber,
          currency: payout.debitCurrency,
          type: payout?.type,
          transferType: payout?.transferType,
          amount: `${payout?.debitCurrency} ${numberWithCommas(
            payout?.totalAmount
          )}`,
          fee: `${payout?.debitCurrency} ${numberWithCommas(payout?.fee)}`,
          status: (
            <StatusView
              status={payout?.status}
              green={"Approved || Successful"}
              red={"Rejected || Failed || Declined"}
              orange={"Processing || Pending"}
              blue={"Awaiting Verification"}
              indigo="Awaiting Approval"
            />
          ),
          action: (
            <PayoutMenu cb={() => getIndividualPayouts(payout?.payoutId)} data={individualPayoutData} />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPayout());
  }, [payout?.data?.items, individualPayoutData]);

  return (
    <Fragment>
      <TableHeader
        pageName="Payouts"
        dataLength={payout?.data?.totalCount }
        data={payout?.data?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        filtering={true}
        exporting={true}
        fetchExportDataHandler={async () => IDBTransaction ? (cb && cb(true)) : dataPayout()}

        FilterComponent={
          <>
            <FilteredUsage
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setBearer={applyFilters}
              clearHandler={clearHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />

      {getPayoutsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnPayouts ? ColumnPayouts : []}
          emptyPlaceHolder="You currently do not have any payouts"
          total={payout?.data?.page?.total}
        />
      )}
    </Fragment>
  );
};

export default TransferPayouts;
