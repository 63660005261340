import React from 'react';
import styles from './Verifyemail.module.scss';
import Logo from '../../../assets/images/Logos/paymentgateway.png';
import { ReactComponent as SignInIcon } from '../../assets/images/Icons/signin.svg';
import { ReactComponent as AccountIcon } from '../../../assets/images/Icons/account.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { openLoader, closeLoader } from '../../../redux/actions/loader/loaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../../../api/client';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { saveSubsidiaryInfo } from '../../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { DataArray } from '@mui/icons-material';
import { getEnvironment } from '../../../utils/getEnvironmentConfig';

const VerifyEmail = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { getStartedState } = useSelector(
		(state) => state.getStartedStateReducer
	);

	console.log({ getStartedState })
	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.get(
				`/subsidiary/dashboard/resend/verification/email?email=${getStartedState}`
			)
			.then((data: any) => {
				dispatch(closeLoader());
				// console.log('data:', data);
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	const handleSubmitTest = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.get(
				`/subsidiary/dashboard/verify/business/email?token=${encodeURIComponent(getStartedState)}`
			)
			.then((data: any) => {
				dispatch(closeLoader());
				// console.log('data:', data);
				sessionStorage.clear();
				history.push("/")
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	return (
		<div className={styles.signInBody}>
			<div className={styles.logo}>
				<img src={Logo} alt='Transactpay' />
			</div>
			<div className={styles.signinForm}>
				<div className={styles.signIn}>
					A mail has been sent to your email address {getStartedState}, verify to sign in.
					<div className={styles.createlink} onClick={handleSubmit}>
						Resend Link
					</div>

					{getEnvironment() == "DEV" && getStartedState && <div className={styles.createlink + " " + styles.createlinkTest} onClick={handleSubmitTest}>
						Verify Test Mode
					</div>}
				</div>
			</div>
			<div className={styles.newAccount}>
				<div className={styles.noAccount}>Back to sign in page</div>
				<div
					className={styles.create}
					onClick={() => {
						history.push('/');
					}}>
					<AccountIcon /> Sign In
				</div>
			</div>
		</div>
	);
};

export default VerifyEmail;
