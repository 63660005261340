import React from "react";

export interface ProductsData {
  productName: React.ReactNode;
  price: string;
  availability: React.ReactNode;
  stockCount: string;
  sold: string;
  revenue: string;
  action: React.ReactNode;
}

export const ColumnProducts = [
  {
    Header: "Product Name",
    accessor: "productName",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Availability",
    accessor: "availability",
  },
  {
    Header: "Stock count",
    accessor: "stockCount",
  },
  {
    Header: "Sold",
    accessor: "sold",
  },
  {
    Header: "Total Amount",
    accessor: "revenue",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
