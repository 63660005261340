import React from "react";

export interface PaymentLinksData {
  name: string;
  paymentType: string;
  limit: string;
  amount: string;
  isActive: React.ReactNode;
  action: React.ReactNode;
  currency: string;
}

export const ColumnPaymentLinks = [
  {
    Header: "Link Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "paymentType",
  },
  {
    Header: "Limit",
    accessor: "limit",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Currency",
    accessor: "currency",
  },
  {
    Header: "Status",
    accessor: "isActive",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
