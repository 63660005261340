import React, { useState } from 'react';
import styles from './Signin.module.scss';
import logo from '../../../assets/images/Logos/paymentgateway.png';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { closeLoader, openLoader } from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { saveSubsidiaryInfo } from '../../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';
import { ToastErrorStyles, ToastSuccessStyles } from '../../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import ForgotPasswordModal from '../../../components/ModalContent/ForgotPasswordModal/ForgotPasswordModal';
import Img from '../../../assets/IconsMerchant/landing.png';
import { onboardingState as onboardingfunc, onVerificationStateAction } from '../../../redux/actions/onboardingState/onbodardingState';
import { saveOnboardRoute } from '../../../redux/actions/onboardlevel/onboardLevelAction';
import { saveMeInfo } from '../../../redux/actions/me/meActions';
import showPwdImg from '../../../assets/images/Icons/show-password.svg';
import hidePwdImg from '../../../assets/images/Icons/hide-password.svg';
import { getProfileDetailsAction, signInAction } from '../../../redux/actions/authentication/authentication';

const SignIn = () => {
	const todaysDate = new Date();
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	interface initTypes {
		Email: string;
		Password?: string;
	}

	const initProps: initTypes = {
		Email: '',
		Password: '',
	};
	const [inputs, setInputs] = React.useState(initProps);

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputs((prevState: initTypes) => {
			return { ...prevState, [event.target.name]: event.target.value, };
		});
	};

	const getMe = async () => {
		try {
			dispatch(openLoader());
			dispatch(await getProfileDetailsAction((data: any, err: any) => {
				dispatch(closeLoader());
				if (err) throw new Error(err?.message)
				dispatch(saveMeInfo(data));
				dispatch(saveOnboardRoute(data?.routeToGetStarted));
				dispatch(onboardingfunc(data?.subsidiaryDetails?.subsidiaries[0]?.businessType));
				dispatch(onVerificationStateAction(data?.subsidiaryDetails?.subsidiaries[0]?.verificationStatus));
				dispatch(saveSubsidiaryInfo(data.data));
				history.push('/dashboard');
			}))
		} catch (error: any) {
			dispatch(closeLoader());
			const message = error?.message;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};
	const handleSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		dispatch(await signInAction(inputs, async (data: any, err: any) => {
			dispatch(closeLoader());
			const { status, message, token } = data ?? {};
			try {
				if (err) throw new Error(err?.message)
				if (token?.accessToken) return await getMe();
					dispatch(saveSubsidiaryInfo({ ...data, email: inputs?.Email }));
					history.push('/2fa-signin');
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastSuccessStyles,
						})
					);

			} catch (error: any) {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: error?.message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}))
	};

	return (
		<div className={styles.signWrapper}>
			<div className={styles.leftwrapper}>
				<div className={styles.image}>
					<img src={logo} alt='' />
				</div>
				<div className={styles.formwrapper}>
					<h1 className={styles.form_heading}>Sign in to your Account</h1>
					<p className={styles.form_p}>
						Enter your details to access your account
					</p>
					<div className={styles.businessForm}>
						<form onSubmit={handleSubmit} className={styles.form}>
							<OutlineTextInput
								style={{ marginTop: '10px' }}
								handleChange={updateProps}
								inputName='Email'
								inputLabel='Email Address'
								inputValue={inputs.Email}
							/>

							<div className={styles.passwordInp}>
								<OutlineTextInput
									style={{ marginTop: '20px', width: '100%' }}
									handleChange={updateProps}
									inputName='Password'
									inputLabel='Password'
									inputType={isRevealPwd ? 'text' : 'password'}
									inputValue={inputs.Password}
								/>

								<img
									alt='/'
									className={styles.inputImg}
									title={isRevealPwd ? 'Hide password' : 'Show password'}
									src={isRevealPwd ? hidePwdImg : showPwdImg}
									onClick={() => setIsRevealPwd((prevState) => !prevState)}
								/>
							</div>

							<p className={styles.forgetPassword}>
								Forgot Password?{' '}
								<span
									onClick={() => setIsOpen(true)}
									className={styles.forgetPasswordspan}>
									Reset here
								</span>
							</p>
							<button onClick={handleSubmit} className={styles.signInButton} disabled={!inputs.Email && !inputs.Password}>
								Sign in
							</button>
						</form>
					</div>
				</div>
				<p className={styles.createuser}>
					New User?{' '}
					<span
						onClick={() => {
							history.push('/sign-up');
						}}
						className={styles.forgetPasswordspan}>
						Create an Account
					</span>
				</p>
			</div>
			<div className={styles.rightwrapper}>
				<img className={styles.rightwrapper_img} src={Img} alt='' />
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<ForgotPasswordModal closeModal={closeModal} />
			</Modal>
		</div>

	);
};

export default SignIn;
