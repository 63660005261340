import client from "./client";

// authentication
const signIn = (payload: any) => client.post("/subsidiary/dashboard/login", payload);
const signInComplete = (payload: any) => client.post("/subsidiary/dashboard/complete/login", payload);
const getProfileDetails = () => client.get("/subsidiary/dashboard/me");
const createAccount = (payload: any) => client.put("users/user/add", payload);
const sendotp = (payload: any) => client.post("users/user/2fa/otp", payload);
const validateEmail = (payload: any) => client.post("users/user/2fa/otp/confirm", payload);
const complete2fASetup = (payload: any) => client.post("users/user/2fa/complete", payload);
const completeOnboardingSteps = (payload: any) => client.patch("/subsidiary/dashboard/onboarding/complete", payload);
const getOnboardingPercentages = () => client.get("/subsidiary/dashboard/onboarding/percentage");

// organizations
const addNewOrganization = (payload: string) => client.post("organizations/create", payload);

export default { signIn, sendotp, createAccount, validateEmail, complete2fASetup, getProfileDetails, addNewOrganization, completeOnboardingSteps, getOnboardingPercentages, signInComplete };