import React from "react";
import styles from "./Createcustomer.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";

const CreateCustomer = ({ closeModal, fetchCustomers }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    firstName: string;
    email: string;
    lastName: string;
    mobile: string;
  }

  const initProps: initTypes = {
    firstName: "",
    email: "",
    lastName: "",
    mobile: "+234",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (
      inputs.firstName &&
      inputs.email &&
      inputs.lastName &&
      inputs.mobile
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const createNewCustomer = async () => {
    try {
      const data: any = await client.post(
        "/subsidiary/customer/create",
        inputs
      );

      const message = data?.data?.message;
      fetchCustomers();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createNewCustomer();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New customer</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="firstName"
            inputLabel="First name"
            inputValue={inputs.firstName}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="lastName"
            inputLabel="Last name"
            inputValue={inputs.lastName}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="email"
            inputLabel="Email address"
            inputValue={inputs.email}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="mobile"
            inputLabel="Mobile Number"
            inputValue={inputs.mobile}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create new customer
          </div>
        ) : (
          <div className={styles.notClickable}>Create new customer</div>
        )}
      </div>
    </div>
  );
};

export default CreateCustomer;
