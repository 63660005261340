import React, { useState } from 'react';
import styles from './Filtered.module.scss';
import type { MenuProps } from 'antd';
import {
	Menu,
	Checkbox,
	Radio,
	Space,
	Collapse,
	DatePicker,
	Input,
} from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import type { RadioChangeEvent } from 'antd';
import moment from 'moment';

const { SubMenu } = Menu;
const { Search } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface menuTypes {
	id: number;
	name: string;
	value: string;
}

function Filtered({
	menuContent,
	dropdown,
	setDropdown,
	setValueStatus,
	valueStatus,
	setValueChannel,
	valueChannel,
	valueGroup,
	setValueGroup,
	setAvailability,
	availability,
	direction,
	setDirection,
	search,
	setSearch,
	email,
	setEmail,
	status = false,
	channel = false,
	group = false,
	avail = false,
	statusActive = false,
	refundfilter = false,
	direct = false,
	searchInput = false,
	settlement = false,
	payout = false,
	emailAvail = false,
}: {
	menuContent?: menuTypes[];
	dropdown: boolean;
	setDropdown: React.Dispatch<React.SetStateAction<boolean>>;
	setValueStatus?: React.Dispatch<React.SetStateAction<string>>;
	setSearch?: React.Dispatch<React.SetStateAction<string>>;
	setValueChannel?: React.Dispatch<React.SetStateAction<string>>;
	setEmail?: React.Dispatch<React.SetStateAction<string>>;
	setValueGroup?: React.Dispatch<React.SetStateAction<string>>;
	setDirection?: React.Dispatch<React.SetStateAction<string>>;
	setAvailability?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
	availability?: boolean;
	valueStatus?: string;
	search?: string;
	email?: string;
	valueChannel?: string;
	direction?: string;
	valueGroup?: string;
	channel?: boolean;
	status?: boolean;
	group?: boolean;
	avail?: boolean;
	statusActive?: boolean;
	refundfilter?: boolean;
	direct?: boolean;
	searchInput?: boolean;
	settlement?: boolean;
	payout?: boolean;
	emailAvail?: boolean;
}) {
	const onChangeChannel = (e: RadioChangeEvent) => {
		// console.log('radio checked', e.target.value);
		setValueChannel && setValueChannel(e.target.value);
	};
	const onChangeStatus = (e: RadioChangeEvent) => {
		// console.log('radio checked', e.target.value);
		setValueStatus && setValueStatus(e.target.value);
	};
	const onChangeGroup = (e: RadioChangeEvent) => {
		// console.log('radio checked', e.target.value);
		setValueGroup && setValueGroup(e.target.value);
	};
	const onChangeAvail = (e: RadioChangeEvent) => {
		// console.log('radio checked', e.target.value);
		setAvailability && setAvailability(e.target.value);
	};
	const onChangeDirection = (e: RadioChangeEvent) => {
		// console.log('radio checked', e.target.value);
		setDirection && setDirection(e.target.value);
	};
	const onChangeSearch = (e: any) => {
		setSearch && setSearch(e.target.value);
	};

	const onChangeSearchEmail = (value: any) => {
		setEmail && setEmail(value);
	};

	return (
		<div>
			<Collapse
				style={{ width: '300px' }}
				className='bg-white '
				bordered={false}
				expandIconPosition='end'
				defaultActiveKey={[
					'1',
					'2',
					'3',
					'4',
					'5',
					'6',
					'7',
					'8',
					'9',
					'10',
					'11',
				]}>
				{channel && (
					<Panel header='Channel' key='1'>
						<Radio.Group onChange={onChangeChannel} value={valueChannel}>
							<Space direction='vertical'>
								{menuContent?.slice(0, 2).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{status && (
					<Panel header='Status' key='2'>
						<Radio.Group onChange={onChangeStatus} value={valueStatus}>
							<Space direction='vertical'>
								{menuContent?.slice(2, 5).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{group && (
					<Panel header='Group' key='3'>
						<Radio.Group onChange={onChangeGroup} value={valueGroup}>
							<Space direction='vertical'>
								{menuContent?.slice(5, 6).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{avail && (
					<Panel header='Availability' key='4'>
						<Radio.Group onChange={onChangeAvail} value={availability}>
							<Space direction='vertical'>
								{menuContent?.slice(6, 8).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{statusActive && (
					<Panel header='Status' key='5'>
						<Radio.Group onChange={onChangeStatus} value={valueStatus}>
							<Space direction='vertical'>
								{menuContent?.slice(8, 10).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{refundfilter && (
					<Panel header='Availability' key='6'>
						<Radio.Group onChange={onChangeStatus} value={valueStatus}>
							<Space direction='vertical'>
								{menuContent?.slice(10, 12).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{direct && (
					<Panel header='Direction' key='7'>
						<Radio.Group onChange={onChangeDirection} value={direction}>
							<Space direction='vertical'>
								{menuContent?.slice(12, 14).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{searchInput && (
					<Panel header='Bank Name' key='8'>
						<Input
							allowClear
							onChange={onChangeSearch}
							// style={{ width: 267 }}
						/>
					</Panel>
				)}

				{settlement && (
					<Panel header='Status' key='9'>
						<Radio.Group onChange={onChangeStatus} value={valueStatus}>
							<Space direction='vertical'>
								{menuContent?.slice(14, 16).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{payout && (
					<Panel header='Status' key='10'>
						<Radio.Group onChange={onChangeStatus} value={valueStatus}>
							<Space direction='vertical'>
								{menuContent?.slice(16, 19).map((item) => (
									<Radio value={item.value}>{item.name}</Radio>
								))}
							</Space>
						</Radio.Group>
					</Panel>
				)}

				{emailAvail && (
					<Panel header='Email' key='11'>
						<Search
							allowClear
							onSearch={onChangeSearchEmail}
							// style={{ width: 267 }}
						/>
					</Panel>
				)}

				<Panel header='Date Range' key='11'>
					<RangePicker
						ranges={{
							Today: [moment(), moment()],
							'This Month': [
								moment().startOf('month'),
								moment().endOf('month'),
							],
						}}
					/>
				</Panel>
			</Collapse>
			<div className={styles.buttonwrapper}>
				<button
					onClick={() => setDropdown(false)}
					className={styles.resetFilter}>
					Reset
				</button>
				<button
					onClick={() => setDropdown(false)}
					className={styles.applyFilter}>
					Apply Filter
				</button>
			</div>
		</div>
	);
}

export default Filtered;
