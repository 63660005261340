const initialAuthState = {
	onboardingState: 'Individual',
	onVerificationState: '',
};

export const onboardingStateReducer = (state = initialAuthState, action) => {
	switch (action.type) {
		case 'ONBOARDINGSTATE': {
			return {
				...state,

				onboardingState: action.onboardingState,
			};
		}
		case 'ONVERIFICATION': {
			return {
				...state,

				onVerificationState: action.onVerificationState,
			};
		}

		default: {
			return state;
		}
	}
};

export default onboardingStateReducer;
