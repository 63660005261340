import { ONBOARDINGSTATE, ONVERIFICATION } from '../constants';
import { ReactNode } from 'react';

export const onboardingState = (onboardingState: string) => {
	return {
		type: ONBOARDINGSTATE,
		onboardingState,
	};
};

export const onVerificationStateAction = (onVerificationState: string) => {
	return {
		type: ONVERIFICATION,
		onVerificationState,
	};
};
