import React, { useState, useEffect } from 'react';
import styles from './ChargebackDetails.module.scss';
import client from '../../../api/client';
import chargeback from '../../../api/chargeback';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import StatusView from '../../../components/StatusView/StatusView';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import useApi from '../../../hooks/useApi';
import TreatChargeback from '../../../components/ModalContent/TreatChargeback/TreatChargeback';

const ChargebackDetails = () => {
	const history = useHistory();
	const params: any = useParams();
	const chargebackId = params.id;
	const chargebackApi = useApi(chargeback.getChargebackDetails);
	const chargebackDetails = chargebackApi?.data?.data || {};
	const chargebackData = chargebackDetails?.chargeback || {};
	const orderData = chargebackDetails?.order || {};
	const [verifyPayoutModalIsOpen, setVerifyPayoutModalIsOpen] = React.useState(false);
	function closeVerifyPayoutModalIsOpen() {
		setVerifyPayoutModalIsOpen(false);
		// cb && cb();
	}

	useEffect(() => {
		chargebackApi.request(chargebackId)
	}, [chargebackId])



	return (
		<div>
			{chargebackApi.loading && false ? (
				<div />
			) : (
				<>
					<div className={styles.transactionInfo}>
						<div className={styles.transactionsHeader}>
							<div
								style={{
									color: '#616161',
									display: 'inline',
									cursor: 'pointer',
								}}
								onClick={() => history.goBack()}>
								Chargebacks
							</div>
							{' > '} Chargeback details
						</div>
					</div>
					<div className={styles.topTable}>
						<div className={styles.detailsHeader}>Chargeback details
							<div className={styles.chargeBackButtonWrapper}>
								<button
									className={styles.chargeBackbutton}
									onClick={() => setVerifyPayoutModalIsOpen(true)}>
									<div className={styles.ml}>Treat Chargeback</div>
								</button>
							</div>
						</div>
						<div className={styles.tableContent}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Chargeback Date</div>
								<div className={styles.detailsKey}>
									{moment(chargebackData?.dateCreated).format('MMMM Do YYYY, h:mm a')}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Status</div>
								<div className={styles.detailsKey}>
									<StatusView
										status={chargebackData?.chargebackStatus?.name}
										green='Successful'
										blue='Initiated'
										orange='Pending'
										red='Failed'
									/>
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment channel</div>
								<div className={styles.detailsKey}>
									{orderData?.paymentType || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer name</div>
								<div className={styles.detailsKey}>
									{orderData?.customer?.firstName} {orderData?.customer?.lastName}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer email</div>
								<div className={styles.detailsKey}>
									{orderData?.customer?.emailAddress}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer phone</div>
								<div className={styles.detailsKey}>
									{orderData?.customer?.mobileNumber}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant Name</div>
								<div className={styles.detailsKey}>
									{orderData?.subsidiary?.name}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant Id</div>
								<div className={styles.detailsKey}>
									{orderData?.subsidiary?.id || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant Email</div>
								<div className={styles.detailsKey}>
									{orderData?.subsidiary?.supportEmail || 'Not Available'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Transaction Currency</div>
								<div className={styles.detailsKey}>
									{chargebackData?.currency?.name}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Chargeback Amount</div>
								<div className={styles.detailsKey}>
									{orderData?.currency?.shortName}{chargebackData?.amount}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Transaction amount</div>
								<div className={styles.detailsKey}>
									{orderData?.currency?.shortName}{orderData?.amount}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Total Charged amount</div>
								<div className={styles.detailsKey}>
									{orderData?.currency?.shortName}{orderData?.totalChargedAmount}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment Reference</div>
								<div className={styles.detailsKey}>
									{orderData?.paymentReference}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Order Reference</div>
								<div className={styles.detailsKey}>
									{orderData?.orderReference}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Fee</div>
								<div className={styles.detailsKey}>
									{orderData?.fee}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer Fee / Merchant Fee</div>
								<div className={styles.detailsKey}>
									{orderData?.customerFee} / {orderData?.subsidiaryFee}
								</div>
							</div>
							<div></div>
						</div>
						<div className={styles.merchantDispute}>
							<h3>Feedback Threads</h3>
							<div className={styles.merchantDisputeItemWrapper}>
								{[...(chargebackData.subsidiaryChargebackDispute || [])]?.reverse()?.map((scd: any) => (
									<div key={scd.id} className={styles.merchantDisputeItem}>
										<span className={styles.merchantDisputeItemActor}>{scd?.isAdmin ? "Admin" : "Merchant"}</span>
										&nbsp;
										{scd?.isFinalComment && <span className={styles.merchantDisputeItemActor}>{scd?.isFinalComment ? "Final Comment" : ""}</span>}
										<h4 className={styles.merchantDisputeItemComment}>Comment: {scd?.comment}</h4>
										<div>
											Files:
											{scd?.subsidiaryChargebackDisputeProof?.map((scdp: any) => (
												<div key={scdp.id}> <a href={scdp?.proof} target='_blank'>{scdp?.proof.split("=")[1]}</a> </div>
											))}
										</div>
										<h4 className={styles.merchantDisputeItemTime}>{moment(scd?.dateCreated).format('MMMM Do YYYY, h:mm a')}</h4>
									</div>
								))}
							</div>
						</div>
					</div>
					<Modal
						isOpen={verifyPayoutModalIsOpen}
						onRequestClose={closeVerifyPayoutModalIsOpen}
						contentLabel="Example Modal"
						style={customStyles}>
						<TreatChargeback data={chargebackData} closeModal={closeVerifyPayoutModalIsOpen} />
					</Modal>
				</>
			)}
		</div>
	);
};

export default ChargebackDetails;
