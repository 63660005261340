import React from 'react';

export interface BeneficiaryData {
	date: string;
	type: string;
	recipient: string;
	name: string;
	action: any;
}

export const ColumnBeneficiary = [
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Type',
		accessor: 'type',
	},
	{
		Header: 'Recipient',
		accessor: 'recipient',
	},
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
