import React, { useEffect, useState } from 'react';
import styles from './Transfer.module.scss';
import { Divider, Tabs } from 'antd';
import Grid from '@material-ui/core/Grid';
import Card from '../../components/Card/OtherBalanceCard';
import BlockIcon from '../../assets/images/Icons/blockfunds.svg';
import BalanceIcon from '../../assets/images/Icons/newBalance.svg';
import BankIcon from '../../assets/images/Icons/newBank.svg';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import { ReactComponent as CloseIcon } from '../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import balanceApi from '../../api/walletBalance';
import useApi from '../../hooks/useApi';
import client from '../../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import TransferModal from '../beneficiary/TransferModal';
import InitiatePayout from '../../components/ModalContent/InitiatePayout/InitialPayout';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import TransferPayouts from '../TransferPayouts/TransferPayouts';

interface propTypes {
	wallets: [
		{
			ledgerBalanceChange: number;
			availableBalanceChange: number;
			availableBalance: number;
			ledgerBalance: number;
			overdraftLimit: number;
			currencyShortName: string;
			currencyName: string;
			walletType: string;
			walletRestriction: string;
			totalTransfer: number;
			successfulTransfer: number;
			walletId: number;
			accountDetails: {
				bankName: string;
				bankCode: string;
				accountName: string;
				accountNumber: string;
			};
		}
	];
	status: string;
	status_code: string;
	message: string;
}
interface PayoutFilters {
  page?: number;
  size?: number;
  searchText?: string;
  keyword?: string,
  SearchableColumns?: string,
  status?: string;
  customerEmail?: string;
  customerId?: number;
  id?: number;
  guid?: string;
  fromDate?: string;
  toDate?: string;
}
function Transfer() {
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [modalTransferIsOpen, setTransferIsOpen] = React.useState(false);
	const [payout, setPayout] = useState<any>();
	const [beneficiary, setBeneficiary] = useState<any>();
	const { currency } = useSelector((state) => state.appSettingsReducer);
	const [subb_id, setsubb_id] = useState('')
	//get current subsidiary Id
	const { activeSubsidiary } = useSelector((state) => state.subsidiaryInfoReducer);

	const dispatch = useDispatch();
	function closeModal() {
		setIsOpen(false);
	}
	function closeModalTransfer() {
		setTransferIsOpen(false);
	}
	const paymentTypes: any[] = [];
	const [selectedPaymentType, setSelectedPaymentType] = React.useState<any>();
	const [inputs, setInputs] = React.useState<string>('');
	const WalletBalanceApi = useApi(balanceApi.getWalletBalance);

	const fetchWalletBalance = async () => {
		WalletBalanceApi.request();
	};

	const walletBalance = WalletBalanceApi?.data?.wallets.find((x: any) => x.currencyShortName == currency) ?? {};
	const fetchBeneficiary = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/subsidiary/beneficiary/all`
			);
			setBeneficiary(data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchWalletBalance();
		fetchBeneficiary();
	}, [currency]);

	useEffect(() => {
		if (activeSubsidiary && activeSubsidiary.id) {
			setsubb_id(activeSubsidiary.id);
		}
	}, [activeSubsidiary]);
	
	let selectedPaymentTypeOptions = paymentTypes.map(function (
		selectedPaymentType: any
	) {
		return {
			value: selectedPaymentType.code,
			label: selectedPaymentType.paymentLinkName,
		};
	});
	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputs(event.target.value);
	};
	const availableBalance = walletBalance?.availableBalance ?? 0;
	const totalTransfer = walletBalance?.totalTransfer ?? 0;
	const successfulTransfer = walletBalance?.successfulTransfer ?? 0;

	const fetchPayout = async (filters: PayoutFilters = {}) => {
		if (!subb_id) {
			console.error("subb_id is not available, skipping API call");
			return;
		}
		dispatch(openLoader());
		  // Destructure filters with default values
		  const {
			page = '',
			size = '',
			keyword = '',
			SearchableColumns = '',
			// searchText = '',
			// status = '',
			// customerEmail = '',
			// customerId = '',
			// id = '',
			// guid = '',
			fromDate = '',
			toDate = '',
		  } = filters;
			

		// Build the query string only with the values that are provided
		const queryParams = new URLSearchParams();

		if (page) queryParams.append('page', page.toString());
		if (size) queryParams.append('size', size.toString());
		// if (searchText) queryParams.append('searchText', searchText);
		// if (status) queryParams.append('status', status);
		// if (customerEmail) queryParams.append('customerEmail', customerEmail);
		// if (customerId) queryParams.append('customerId', customerId.toString());
		// if (id) queryParams.append('id', id.toString());
		// if (guid) queryParams.append('guid', guid);
		if (fromDate) queryParams.append('fromDate', fromDate);
		if (toDate) queryParams.append('toDate', toDate);
		if (keyword) queryParams.append('keyword', keyword);
		if (SearchableColumns) queryParams.append('SearchableColumns', SearchableColumns);

		try {
			const { data }: any  = await client.get(
				`/subsidiary/payout/all?${queryParams.toString()}`,
				{
					headers: {
						'subb_id': subb_id,
					},
				}
			);
			setPayout(data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};
	useEffect(() => {
		if (subb_id) {
			fetchPayout();  // Only fetch if subb_id is available
		}
	}, [subb_id]);
	

	const Naira = () => {
		return (
			<div className={styles.grids}>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12} lg={4}>
						<Card
							currency={currency}
							icon={BalanceIcon}
							amount={availableBalance}
							transactionType='Payout Balance'
						/>
					</Grid>
					<Grid item md={6} xs={12} lg={4}>
						<Card
							currency={currency}
							icon={BlockIcon}
							amount={totalTransfer}
							transactionType='Total Transfer'
						/>
					</Grid>
					<Grid item md={6} xs={12} lg={4}>
						<Card
							currency={currency}
							icon={BankIcon}
							amount={successfulTransfer}
							transactionType='Successful Transfers'
							count
						/>
					</Grid>
				</Grid>
				{payout?.data?.totalCount >= 0 ? (
					<div style={{ marginTop: '10px' }}>
						<button
							onClick={() => setTransferIsOpen(true)}
							className={styles.wordsbutton}>
							Make a Transfer
						</button>
						<TransferPayouts cb={fetchPayout} payout={payout} subb_id={subb_id} />
					</div>
				) : (
					<div className={styles.words}>
						<h5 className={styles.wordsh5}>You have not made any transfers</h5>
						<p className={styles.wordsp}>
							But, you can change that. You can start by initiating your first
							transfer to either a Transactpay merchant’s email address or to a
							bank account.
						</p>
						<button
							onClick={() => setTransferIsOpen(true)}
							className={styles.wordsbutton}>
							Make a Transfer
						</button>
					</div>
				)}
			</div>
		);
	};

	const items = [
		{
		  key: '1',                // Unique key for the tab
		  label: currency,       // The label displayed on the tab
		  children: <Naira />,     // Content to display when the tab is active
		},
		// {
		//   key: '2',
		//   label: 'USD',
		//   children: <div> {/* Your content here */} </div>,
		//   disabled: true, 
		// },
		// {
		//   key: '3',
		//   label: 'EUR',
		//   children: <div> {/* Your content here */} </div>,
		//   disabled: true, 
		// },
		// {
		//   key: '4',
		//   label: 'GPB',
		//   children: <div> {/* Your content here */} </div>,
		//   disabled: true, 
		// },
	];

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<h3 className={styles.headerh3}>Transfers</h3>
				{/* <button onClick={() => setIsOpen(true)} className={styles.headerbutton}>
					Fund Balance
				</button> */}
			</div>
			<div className={styles.tabswrapper}>
				<Tabs
					tabBarStyle={{ background: '#ffffff' }}
					className='tab-header'
					defaultActiveKey='1'
					items={items} // Pass the items array here
				/>
			</div>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<div className={styles.modalwrapper}>
					<div className={styles.modalhead}>
						<h3 className={styles.modalheadh3}>Fund Balance</h3>
						<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<div className={styles.modalcontent}>
						<p className={styles.modalcontentp}>
							To fund your naira wallet, make a transfer to the account details
							below
						</p>

						<div className={styles.modalcontentbox}>
							<div className={styles.modalcontentboxflex}>
								<p className={styles.modalcontentboxp}>Bank name:</p>
								<p className={styles.modalcontentboxp}>
									{walletBalance?.accountDetails?.bankName}
								</p>
							</div>
							<div className={styles.modalcontentboxflex}>
								<p className={styles.modalcontentboxp}>Account number:</p>
								<p className={styles.modalcontentboxp}>
									{walletBalance?.accountDetails?.accountNumber}

									<span>
										<CopyIcon />
									</span>
								</p>
							</div>
							<div className={styles.modalcontentboxflex}>
								<p className={styles.modalcontentboxp}>Account name:</p>
								<p className={styles.modalcontentboxp}>
									{' '}
									{walletBalance?.accountDetails?.accountName}
								</p>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={modalTransferIsOpen}
				onRequestClose={closeModalTransfer}
				contentLabel='Example Modal'
				style={customStyles}>
				<InitiatePayout
					closeModal={closeModalTransfer}
					fn={fetchWalletBalance}
					fn2={fetchPayout}
					beneficiary={beneficiary}
				/>
			</Modal>
		</div>
	);
}

export default Transfer;
