import React, { useState, useEffect } from 'react';
import BankDetails from './BankDetails/BankDetails';
import styles from './compliance.module.scss';
import Information from '../ComplianceInfo/Information/Information';
import complianceApi from '../../api/compliance';
import useApi from '../../hooks/useApi';

import Profile from './Profile/Profile';
import Kyc from './Kyc/Kyc';
import { Divider } from 'antd';

const ComplianceInfo = () => {
	const [selection, setSelection] = useState('Profile');

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapper_first}>
				<div
					className={
						selection === 'Profile' ? styles.selectionActive : styles.selection
					}
					onClick={() => setSelection('Profile')}>
					Profile
				</div>

				<Divider
					style={{ height: '28px', color: '#C2C2C2', width: '1px' }}
					type='vertical'
				/>
				<div
					className={
						selection === 'Bank Details'
							? styles.selectionActive
							: styles.selection
					}
					onClick={() => setSelection('Bank Details')}>
					Bank Details
				</div>
				<Divider
					style={{ height: '28px', color: '#C2C2C2', width: '1px' }}
					type='vertical'
				/>

				<div
					className={
						selection === 'Information'
							? styles.selectionActive
							: styles.selection
					}
					onClick={() => setSelection('Information')}>
					Director’s Information
				</div>
				<Divider
					style={{ height: '28px', color: '#C2C2C2', width: '1px' }}
					type='vertical'
				/>

				<div
					className={
						selection === 'Kyc' ? styles.selectionActive : styles.selection
					}
					onClick={() => setSelection('Kyc')}>
					KYC Details
				</div>
			</div>
			<div className={styles.wrapper_second}>
				{selection === 'Profile' && (
					<Profile selection={selection} setSelection={setSelection} />
				)}
				{selection === 'Bank Details' && (
					<BankDetails selection={selection} setSelection={setSelection} />
				)}
				{selection === 'Information' && (
					<Information selection={selection} setSelection={setSelection} />
				)}
				{selection === 'Kyc' && (
					<Kyc selection={selection} setSelection={setSelection} />
				)}
			</div>
		</div>
	);
};

export default ComplianceInfo;
