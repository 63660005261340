const initialAuthState = {
	getStartedState: "",
};

export const getStartedStateReducer = (state = initialAuthState, action) => {
	switch (action.type) {
		case 'GETSTARTEDSTATE': {
			return {
				...state,

				getStartedState: action.getStartedState,
			};
		}

		default: {
			return state;
		}
	}
};

export default getStartedStateReducer;
