import React, { useState, Fragment, useEffect } from 'react';
import styles from './Audittrails.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import { AuditColumn, AuditData } from '../../types/TablesTypes/AuditTrails';
import ExportButton from '../../components/ExportButton/ExportButton';
import StatusView from '../../components/StatusView/StatusView';
import { ReactComponent as DropArrow } from '../../assets/images/Icons/drop-arrow.svg';
import auditTrailsApi from '../../api/auditTrails';
import useApi from '../../hooks/useApi';
import { numberWithCommas } from '../../utils/formatNumber';
import TableHeader from '../../components/TableHeader/TableHeader';
import moment from 'moment';
import Filtered from '../../components/filterComponent/Filtered';

const AuditTrails = () => {
	const [tableRow, setTableRow] = useState<any[]>();
	const [dropdown, setDropdown] = useState(false);
	const getAuditTrailsApi = useApi(auditTrailsApi.getAuditTrails);

	useEffect(() => {
		getAuditTrailsApi.request();
	}, []);

	const dataTransaction = () => {
		const tempArr: AuditData[] = [];
		getAuditTrailsApi?.data?.items
			?.slice(0)
			.reverse()
			.forEach((audit: AuditData, index: number) => {
				tempArr.push({
					name: audit?.name,
					role: audit?.role,
					emailAddress: audit?.emailAddress,
					action: audit?.action,
					ipAddress: audit?.ipAddress || 'N / A',
					activityType: audit?.activityType,
					dateCreated: moment(audit?.dateCreated).format(
						'MMMM Do YYYY, h:mm a'
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [getAuditTrailsApi?.data?.items]);
	return (
		<Fragment>
			<TableHeader
				pageName='Audit Trails'
				dataLength={getAuditTrailsApi?.data?.totalItems}
				data={getAuditTrailsApi?.data?.items}
				dropdown={dropdown}
				setDropdown={setDropdown}
				filtering={false}
				FilterComponent={
					<>
						<Filtered dropdown={dropdown} setDropdown={setDropdown} />
					</>
				}
			/>
			{getAuditTrailsApi.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					data={tableRow ? tableRow : []}
					columns={AuditColumn ? AuditColumn : []}
					emptyPlaceHolder='You currently do not have any audit'
					total={getAuditTrailsApi?.data?.totalItems}
				/>
			)}
		</Fragment>
	);
};

export default AuditTrails;
