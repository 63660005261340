import React, { useEffect } from 'react';
import styles from './Refund.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';

function Single({ closeModal, fn }: any) {
	interface initTypes {
		transactionReference: string;
		amount: string;
		reason: string;
	}

	const initProps: initTypes = {
		transactionReference: '',
		amount: '',
		reason: '',
	};
	const [inputs, setInputs] = React.useState(initProps);
	const dispatch = useDispatch();

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.post('/subsidiary/dashboard/refund', [{ ...inputs }])
			.then((data: any) => {
				dispatch(closeLoader());
				closeModal();
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);

				fn();
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Log a single refund</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='amount'
						inputLabel='Amount'
						inputValue={inputs.amount}
						style={{ width: '100%' }}
					/>
				</div>

				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='transactionReference'
						inputLabel='Transaction reference'
						inputValue={inputs.transactionReference}
						style={{ width: '100%' }}
					/>
				</div>

				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='reason'
						inputLabel='Reason for refund'
						inputValue={inputs.reason}
						style={{ width: '100%' }}
					/>
				</div>

				<Divider style={{ margin: 0, padding: 0 }} />

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={closeModal}>
						Cancel
					</button>

					<button onClick={handleSubmit} className={styles.fund}>
						Log Refund
					</button>
				</div>
			</div>
		</div>
	);
}

export default Single;
