import React, { useState, useEffect } from 'react';
import styles from './TopBar.module.scss';
import { useDispatch } from 'react-redux';
import { ReactComponent as CustomRoleIcon } from '../../assets/IconsMerchant/u_selfie.svg';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';

function TopBar({
	dataRef,
	viewRoleDetails,
	setIsOpenCreate,
	selectedNav,
	setSelectedNav,
}: any) {
	const dispatch = useDispatch();
	const changeHandler = (id: any) => {
		dispatch(openLoader());
		viewRoleDetails(id);
		setSelectedNav(id);
		setTimeout(() => {
			dispatch(closeLoader());
		}, 2000);
	};
	return (
		<>
			<div className={styles.wrapperf}>
				<div className={styles.tabContainer}>
					{dataRef &&
						dataRef.map(({ name, id, userCount }: any) => (
							<div
								key={id}
								onClick={() => changeHandler(id)}
								className={
									selectedNav === id
										? styles.activeMenuItem
										: styles.subMenuItem
								}>
								{selectedNav === id ? (
									<div className={styles.activeItemColor}>
										{name} ({userCount})
									</div>
								) : (
									<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>
										{name} ({userCount})
									</div>
								)}
							</div>
						))}
				</div>

				<div
					onClick={() => setIsOpenCreate(true)}
					className={styles.rolesButton}>
					<CustomRoleIcon />
					<span>Create custom role</span>
				</div>
			</div>
		</>
	);
}

export default TopBar;
