import {
	SAVE_ONBOARDLEVEL_INFO,
	SAVE_ONBOARDSTATUS_INFO,
	SAVE_ONBOARDROUTE_INFO,
	SAVE_STARTED,
} from '../constants';

export const saveOnboardLevelInfo = (onboardLevelInfo: any) => {
  return {
		type: SAVE_ONBOARDLEVEL_INFO,
		onboardLevelInfo,
	};
};

export const saveOnboardStateInfo = (onBoardStatus: any) => {
	return {
		type: SAVE_ONBOARDSTATUS_INFO,
		onBoardStatus,
	};
};

export const saveOnboardRoute = (route: any) => {
	return {
		type: SAVE_ONBOARDROUTE_INFO,
		route,
	};
};

export const saveStarted = (start: boolean) => {
	return {
		type: SAVE_STARTED,
		start,
	};
};
