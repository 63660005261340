import { GET_COUNTRIES, GET_CURRENCIES } from "../../actions/constants";

const initialNavbarState = {
	currency: 'NGN',
	countries: [],
	currencies: []
};

export const appSettingsReducer = (state = initialNavbarState, action: any) => {
	switch (action.type) {
		case 'CHANGE_CURRENCY': {
			return {
				...state,
				currency: action.currency,
			};
		}
		case GET_CURRENCIES: {
			return {
				...state,
				currencies: action.currencies,
			};
		}
		case GET_COUNTRIES: {
			return {
				...state,
				countries: action.countries,
			};
		}
		default: {
			return state;
		}
	}
};

export default appSettingsReducer;